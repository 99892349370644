import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import NextButton from 'components/NextButton'
import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    marginTop: theme.spacing(4)
  }
}))

const SeriousnessCheckerDisclaimer = ({ goNextPage, t }) => {
  const classes = useStyles()

  useEffect(() => trackEvent('PageView - SeriousnessCheckerDisclaimer'), [])

  const setAndProceed = event => {
    event.preventDefault()
    trackEvent('Submit - SeriousnessCheckerDisclaimer')
    goNextPage()
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          {t('pages.SeriousnessCheckerDisclaimer.title')}
        </MedTypography>
        <MedTypography variant='body1'>
          {t('pages.SeriousnessCheckerDisclaimer.text')}
        </MedTypography>
      </div>
      <NextButton
        label={t('pages.SeriousnessCheckerDisclaimer.next')}
        onClick={setAndProceed}
      />
    </>
  )
}

export default withTranslation()(SeriousnessCheckerDisclaimer)
