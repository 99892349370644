import { array, object, number } from 'yup'
import { get } from 'lodash'

import { convertToDate } from 'utils/helper'
import { dateShape } from 'utils/validation'

const CovidVaccinesSchema = {
  covid: array().of(
    object().shape({
      dose: number().test(
        'right-dose-order',
        'Dose order is invalid',
        function (value) {
          const { path, options } = this
          if (!value || value === 1) {
            return true
          }
          const covidVaccines = get(options.context, 'temp.vaccines.covid')
          const index = path.substring(
            path.indexOf('covid[') + 6,
            path.indexOf(']')
          )
          if (
            get(covidVaccines, `[${index}].date.mm`, '') === '' ||
            get(covidVaccines, `[${index}].date.yyyy`, '') === ''
          ) {
            return true
          }
          const valueIsDaySpecific =
            get(covidVaccines, `[${index}].date.dd`, '') !== ''
          const valueDate = convertToDate(get(covidVaccines, `[${index}].date`))
          return (
            covidVaccines
              .filter(
                vaccine =>
                  vaccine.dose !== undefined &&
                  vaccine.dose < value &&
                  get(vaccine, 'date.mm', '') !== '' &&
                  get(vaccine, 'date.yyyy', '') !== ''
              )
              .filter(
                vaccine =>
                  valueDate.getTime() < convertToDate(vaccine.date).getTime() ||
                  (valueIsDaySpecific &&
                    get(vaccine, `date.dd`, '') !== '' &&
                    valueDate.getTime() ===
                      convertToDate(vaccine.date).getTime())
              ).length === 0
          )
        }
      ),
      date: dateShape.isFuture('dateShouldNotBeInFuture')
    })
  )
}

export default CovidVaccinesSchema
