import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation, Trans } from 'react-i18next'

import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { ReactComponent as MedWatcherLogo } from 'assets/medwatcher.svg'
import { ReactComponent as MedWatcherWomanImageResult } from 'assets/medwatcher-woman-result.svg'

const useStyles = makeStyles(theme => ({
  medWatcherLink: {
    textDecoration: 'none !important'
  },
  medWatcherWrapperResult: {
    borderRadius: '24px',
    border: '5px solid #35AEB1',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
  },
  medWatcherContainer: {
    display: 'flex',
    height: 'auto',
    paddingLeft: '32px',
    ['@media (max-width:470px)']: {
      paddingLeft: '32px'
    }
  },
  medWatcherImageContainer: {
    width: '40%',
    margin: 'auto',
    ['@media (max-width:470px)']: {
      width: '0%'
    },
    '& svg': {
      width: '100%',
      ['@media (max-width:470px)']: {
        display: 'none'
      }
    }
  },
  medWatcherContentResult: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 20px 20px',
    color: '#0f4c5c',
    '& svg': {
      height: '32px',
      margin: '20px auto 30px 30px'
    }
  },
  medWatcherFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#35AEB1',
    borderRadius: '0px 0px 24px 24px',
    margin: '0px -5px -5px',
    color: '#FFF',
    fontSize: '16px',
    padding: '8px 16px 11px'
  }
}))

const BannerMedWatcherSmall = props => {
  const classes = useStyles()

  return (
    <Link
      href='https://www.medwatcher.io'
      target='_blank'
      className={classes.medWatcherLink}
    >
      <div className={classes.medWatcherWrapperResult}>
        <div className={classes.medWatcherContainer}>
          <div className={classes.medWatcherImageContainer}>
            <MedWatcherWomanImageResult />
          </div>
          <div className={classes.medWatcherContentResult}>
            <MedWatcherLogo />
            <Trans
              i18nKey='bannerMedWatcher.small.medWatcherText'
              values={{
                break: '<br />'
              }}
            />
          </div>
        </div>
        <div className={classes.medWatcherFooter}>
          {props.t('bannerMedWatcher.small.moreInformation')}
          <NavigateNextIcon />
        </div>
      </div>
    </Link>
  )
}

export default withTranslation()(BannerMedWatcherSmall)
