import React, { useState, useCallback, useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { debounce } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import MedSearchBox from '@bit/medikura.med-lib-uno.med-search-box'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import InfoTooltip from 'components/InfoTooltip'
import NextButton from 'components/NextButton'

import { searchDrugs } from 'service'
import { handleOnSearch, handleOnSelect } from 'utils/autocomplete'
import {
  highlight,
  extractChipsFromAutoCompleteText,
  searchBoxFilterNone
} from 'utils/helper'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  },
  covidHint: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    borderRadius: theme.spacing(1)
  }
}))

const Drugs = props => {
  const classes = useStyles()

  const [drugsList, setDrugsList] = useState([])

  useEffect(() => trackEvent('PageView - Drugs'), [])

  const handleOnSearchDrug = useCallback(
    debounce(searchText => {
      trackEvent('AutoComplete - Drugs', '', false)
      handleOnSearch(
        searchDrugs,
        searchText,
        'medicine',
        { medicine: searchText },
        setDrugsList
      )
    }, 300),
    []
  )

  const handleOnSelectDrug = (value, item) => {
    setDrugsList([])
    handleOnSelect(value, item, setDrugsList, props, 'drugs')
  }

  const filterInputValue = value => {
    const { chips, remainingText } = extractChipsFromAutoCompleteText(value)

    if (!remainingText.trim()) {
      setDrugsList([])
    }

    if (!chips) {
      return value
    }

    const newChips = []
    for (const chip of chips) {
      newChips.push({
        medicine: chip
      })
    }
    props.setFieldValue(`drugs`, [...props.values.drugs, ...newChips])

    trackEvent('AutoChip - Drugs', '', false)

    return remainingText
  }

  const corrections = () => {
    // Before going to next page, check and set free text
    let isAnyDrugSuspected = false
    props.values.drugs.forEach((drug, index) => {
      if (typeof drug === 'string') {
        const item = {
          medicine: drug
        }
        props.setFieldValue(`drugs[${index}]`, item)
      }
      if (props.values.suspectedDrugs.includes(drug.medicine)) {
        isAnyDrugSuspected = true
      }
    })
    if (!isAnyDrugSuspected) {
      props.values.suspectedDrugs = []
    }
  }

  const handleNext = event => {
    event.preventDefault()
    trackEvent('Submit - Drugs', '' + props.values.drugs.length)
    corrections()
    props.setDrugs(
      props.values.drugs.map((drug, index) => ({ index, drug })),
      props.goNextPage
    )
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.Drugs.heading'>
            <strong />
          </Trans>
          {/* props.ab === 'b' && (
            <InfoTooltip
              paragraphs={[
                props.t('pages.Drugs.tooltip.paragraph1'),
                props.t('pages.Drugs.tooltip.paragraph2')
              ]}
              page='Drugs'
            />
          ) */}
        </MedTypography>
        <MedSearchBox
          chips
          freeText
          isAutoFocusEnabled
          inputId='drugsSearch'
          placeholder={props.t('pages.Drugs.searchPlaceholder')}
          label={props.t('pages.Drugs.searchLabel')}
          filterList={searchBoxFilterNone}
          dataList={drugsList}
          value={props.values.drugs}
          onChange={handleOnSearchDrug}
          onSelect={handleOnSelectDrug}
          filterInputValue={filterInputValue}
          renderOption={(option, state) => (
            <div style={{ cursor: 'pointer' }}>
              {highlight(option.medicine, state.inputValue)}
              {option.firmenname && (
                <MedTypography variant='body2' style={{ padding: 0 }}>
                  {option.firmenname}
                </MedTypography>
              )}
            </div>
          )}
          variant='filled'
          optionLabelKey='medicine'
        />
        <MedTypography variant='body2' className={classes.covidHint}>
          <Trans i18nKey='pages.Drugs.covidHint'>
            <strong />
          </Trans>
        </MedTypography>
      </div>
      <NextButton
        disabled={
          props.values.drugs.length === 0 &&
          props.values.temp.vaccines.covid.length === 0
        }
        onClick={handleNext}
      />
    </>
  )
}
export default withTranslation()(Drugs)
