import axios from 'axios'
import config from 'config'

import * as Sentry from '@sentry/browser'

const getEffects = (atcCode, language = 'de') => {
  return new Promise((resolve, reject) => {
    return axios
      .get(
        `${config.INSIGHTS_BASE_URL}/effects/atc/${atcCode}?lang=${language}`,
        {
          headers: _getRequestHeaders()
        }
      )
      .then(response => {
        if (response.data) {
          resolve(response.data)
        }
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
        reject(error)
      })
  })
}

const _getRequestHeaders = token => {
  const headers = {
    'Content-Type': 'application/json',
    'X-API-Version': '3.0.0',
    'X-Event-Version': '3.0.0',
    'X-Event-App-Created': 'med-app-insights',
    'X-Event-Role': 'patient',
    Authorization: token
  }
  return headers
}

export { getEffects }
