import React from 'react'
import { withTranslation } from 'react-i18next'
import { has, range } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

const useStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: theme.spacing(1)
  }
}))

const DateInput = props => {
  const classes = useStyles()

  const monthsDataset = []
  for (let i = 1; i <= 12; i++) {
    monthsDataset.push({
      label: props.t('common.dateInput.months.' + i),
      value: i
    })
  }

  return (
    <>
      <Grid container xs={12} item justify='space-between'>
        <Grid item xs={2} sm={3}>
          <MedDropDown
            formControlClass={classes.formControl}
            name={props.name + '.dd'}
            label={props.t('common.dateInput.labels.day')}
            placeholder={props.t('common.dateInput.placeholders.day')}
            autoNativeOnMobile
            dataList={range(1, 32)}
            value={props.value.dd || ''}
            onChange={props.onChange}
            onBlur={props.onBlur}
            autoFocus={props.autoFocus || false}
            error={has(props, 'error.dd')}
          />
        </Grid>
        <Grid item xs={5} sm={4}>
          <MedDropDown
            formControlClass={classes.formControl}
            name={props.name + '.mm'}
            label={props.t('common.dateInput.labels.month')}
            placeholder={props.t('common.dateInput.placeholders.month')}
            autoNativeOnMobile
            required
            dataList={monthsDataset}
            textKey='label'
            valueKey='value'
            value={props.value.mm || ''}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={has(props, 'error.mm')}
          />
        </Grid>
        <Grid item xs={3}>
          <MedDropDown
            formControlClass={classes.formControl}
            name={props.name + '.yyyy'}
            label={props.t('common.dateInput.labels.year')}
            placeholder={props.t('common.dateInput.placeholders.year')}
            autoNativeOnMobile
            required
            dataList={range(
              new Date().getFullYear(),
              !props.minYear ? 1900 : props.minYear - 1
            )}
            value={props.value.yyyy || ''}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={has(props, 'error.yyyy')}
          />
        </Grid>
        <Grid item xs={12}>
          {props.error ? (
            <MedTypography variant='body2' color='error'>
              {props.t(
                'common.dateInput.errorText.' + props.error,
                props.t('common.dateInput.errorText.default')
              )}
            </MedTypography>
          ) : (
            <MedTypography variant='body2' color='textSecondary'>
              {props.t('common.dateInput.helperText')}
            </MedTypography>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default withTranslation()(DateInput)
