import { object, number, addMethod, string, mixed } from 'yup'
import { isLeapYear, convertToDate, convertToPhonenumber } from 'utils/helper'
import { get } from 'lodash'
import config from '../../config'

import { trackEvent } from 'service/tracker'

// TODO: Convert this into a new Yup schema type 'medDate'.
const dateShape = object().shape({
  dd: number()
    .transform(value => (isNaN(value) ? null : value))
    .min(1)
    .max(31)
    .when('mm', {
      is: val => [4, 6, 9, 11].includes(val),
      then: number().max(30),
      otherwise: number().max(31)
    })
    .when(['mm', 'yyyy'], {
      is: (month, year) => month === 2 && !isLeapYear(year),
      then: number().max(28)
    })
    .when(['mm', 'yyyy'], {
      is: (month, year) => month === 2 && isLeapYear(year),
      then: number().max(29)
    })
    .integer()
    .nullable(),
  mm: number()
    .required()
    .min(1)
    .max(12)
    .integer(),
  yyyy: number()
    .required()
    .min(1900)
    .max(2100)
    .integer()
})

// TODO: Once new Yup schema 'medDate' is created, add this as member method.
addMethod(object, 'isFuture', function (message) {
  return this.test('is-date-in-future', message, function (value) {
    const { path, createError } = this

    if (value.mm && value.yyyy) {
      // if day is not present (because its not mandatory), consider 1st day of the month for date comparison
      const startDateObj = convertToDate(value)
      return startDateObj <= new Date() || createError({ path, message })
    }
  })
})

// TODO: Once new Yup schema 'medDate' is created, add this as member method.
addMethod(object, 'isBefore', function (message) {
  return this.test('is-date-before', message, function (value) {
    const { path, createError, parent } = this

    if (value.mm && value.yyyy) {
      // if day is not present (because its not mandatory), consider last day of the month for date comparison
      const startDateObj = convertToDate(parent.startDate)
      // You don't have to check before for a startDate which isn't set.
      if (isNaN(startDateObj)) {
        return true
      }

      const endDateObj = new Date(
        value.yyyy,
        value.dd ? value.mm - 1 : value.mm,
        value.dd || 0
      )

      return startDateObj <= endDateObj || createError({ path, message })
    }
  })
})

addMethod(object, 'isBeforeEffects', function (message) {
  return this.test('is-before-effects-start-date', message, function (value) {
    const { path, createError, options, parent } = this

    // Only do this validation for endDate if the startDate field isn't available (vaccines only)
    if (
      path.endsWith('.endDate') &&
      get(options.context, path.split('.')[0] + '.startDateRough') !== undefined
    ) {
      return true
    }

    if (value.mm && value.yyyy) {
      let suspectedDrugs = options.context.suspectedDrugs

      // Fallback if no valid suspected drug has been selected
      if (
        suspectedDrugs.length === 0 ||
        (suspectedDrugs.length === 1 && suspectedDrugs[0] === '-')
      ) {
        suspectedDrugs = [options.context.drugs[0].medicine]
      }

      // The date doesn't matter if this drug isn't suspicious
      if (!suspectedDrugs.includes(parent.medicine)) {
        return true
      }

      const drugBeforeEverySideEffect = options.context.effects.every(
        effect => {
          if (effect.startDate.dd === '' || value.dd === '') {
            return (
              convertToDate(value).setDate(1) <=
              convertToDate(effect.startDate).setDate(1)
            )
          }
          return convertToDate(value) <= convertToDate(effect.startDate)
        }
      )
      if (drugBeforeEverySideEffect) {
        return true
      } else {
        trackEvent('Error - DrugSuspectBeforeSideEffect', '', false)
        return createError({ path, message })
      }
    }
  })
})

const phoneShape = string()

addMethod(string, 'phoneTest', function (message) {
  return this.test('test-name', message, function (value) {
    if (value === '') {
      return true
    }
    const { path, createError } = this
    if (!/^[\d \/\+\-]*$/.test(value)) {
      return createError({ path, message })
    }
    return (
      /^(0041|0043|01[5-7])\d{4,19}$/.test(convertToPhonenumber(value)) ||
      createError({ path, message })
    )
  })
})

const mixedShape = mixed()

addMethod(mixed, 'isHeightRealistic', function (message) {
  return this.test('is-height-value-realistic', message, function (value) {
    const { path, createError, parent } = this
    if (
      parent.dateOfBirthYear === new Date().getFullYear() &&
      value > config.ONE_YEAR_OLD_MAX_HEIGHT
    ) {
      return createError({ path, message })
    } else {
      return true
    }
  })
})

addMethod(mixed, 'isWeightRealistic', function (message) {
  return this.test('is-weight-value-realistic', message, function (value) {
    const { path, createError, parent } = this
    if (
      parent.dateOfBirthYear === new Date().getFullYear() &&
      value > config.ONE_YEAR_OLD_MAX_WEIGHT
    ) {
      return createError({ path, message })
    } else {
      return true
    }
  })
})

export { dateShape, phoneShape, mixedShape }
