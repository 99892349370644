import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { get, has } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { hyphenatedText } from 'utils/helper'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'
import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'

import NextButton from 'components/NextButton'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    minHeight: '18rem'
  },
  unitPadding: {
    paddingBottom: theme.spacing(1)
  }
}))

const DrugDosage = ({
  values,
  errors,
  touched,
  itemIndex: index,
  handleChange: formikHandleChange,
  handleBlur: formikHandleBlur,
  setFieldValue,
  goNextPage,
  t
}) => {
  const classes = useStyles()

  const [displayError, setDisplayError] = React.useState(false)

  const isError = has(errors, `drugs[${index}].dose.amount`)

  const drugName = hyphenatedText(values.drugs[index].medicine)

  useEffect(() => trackEvent('PageView - Drugs Dosage ' + (index + 1)), [])

  useEffect(() => {
    // makes formik to run even if no values were changed
    setFieldValue(
      `drugs[${index}].dose.amount`,
      get(values, `drugs[${index}].dose.amount`, '')
    )
  }, [index, setFieldValue, values])

  const handleNext = event => {
    event.preventDefault()

    if (isError) {
      setDisplayError(true)
    } else {
      trackEvent('Submit - Drugs Dosage ' + (index + 1))

      const fieldCount =
        (get(values, `drugs[${index}].dose.unit`, '') !== '' ? 1 : 0) +
        (get(values, `drugs[${index}].dose.amount`, '') !== '' ? 1 : 0) +
        (get(values, `drugs[${index}].dose.interval`, '') !== '' ? 1 : 0)
      if (fieldCount > 0) {
        trackEvent(
          'Submit - Drugs Dosage Completed ' +
            (index + 1) +
            ' - ' +
            fieldCount +
            '/3'
        )
      }
      goNextPage()
    }
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.DrugDosage.heading'>
            <strong>{{ drugName }}</strong>
          </Trans>{' '}
          ({t('common.optional')})
        </MedTypography>
        <div className={classes.unitPadding}>
          <MedDropDown
            className={classes.unitPadding}
            name={`drugs[${index}].dose.unit`}
            label={t('pages.DrugDosage.unit.label')}
            placeholder={t('pages.DrugDosage.unit.placeholder')}
            autoNativeOnMobile
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
            autoFocus
            value={get(values, `drugs[${index}].dose.unit`, '')}
            textKey='label'
            valueKey='value'
            dataList={[
              {
                label: t('pages.DrugDosage.unit.options.piece'),
                value: 'piece'
              },
              {
                label: t('pages.DrugDosage.unit.options.ml'),
                value: 'ml'
              },
              {
                label: t('pages.DrugDosage.unit.options.mg'),
                value: 'mg'
              },
              {
                label: t('pages.DrugDosage.unit.options.drop'),
                value: 'drop'
              },
              {
                label: t('pages.DrugDosage.unit.options.injection'),
                value: 'injection'
              },
              {
                label: t('pages.DrugDosage.unit.options.suppository'),
                value: 'suppository'
              },
              {
                label: t('pages.DrugDosage.unit.options.tablet'),
                value: 'tablet'
              },
              {
                label: t('pages.DrugDosage.unit.options.capsule'),
                value: 'capsule'
              },
              {
                label: t('pages.DrugDosage.unit.options.puff'),
                value: 'puff'
              },
              {
                label: t('pages.DrugDosage.unit.options.ug'),
                value: 'ug'
              },
              {
                label: t('pages.DrugDosage.unit.options.units'),
                value: 'units'
              },
              {
                label: t('pages.DrugDosage.unit.options.unknown'),
                value: 'unknown'
              }
            ]}
          />
        </div>
        <div className={classes.unitPadding}>
          <MedTextField
            type='number'
            fullWidth
            name={`drugs[${index}].dose.amount`}
            label={t('pages.DrugDosage.amount.label')}
            value={get(values, `drugs[${index}].dose.amount`, '')}
            error={displayError && isError}
            helperText={
              displayError && isError
                ? t('pages.DrugDosage.amount.errorText')
                : t('pages.DrugDosage.amount.helperText')
            }
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              inputProps: {
                min: 1
              }
            }}
          />
        </div>
        <div className={classes.unitPadding}>
          <MedDropDown
            name={`drugs[${index}].dose.interval`}
            label={t('pages.DrugDosage.interval.label')}
            placeholder={t('pages.DrugDosage.interval.placeholder')}
            autoNativeOnMobile
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
            value={get(values, `drugs[${index}].dose.interval`, '')}
            textKey='label'
            valueKey='value'
            dataList={[
              {
                label: t('pages.DrugDosage.interval.options.daily'),
                value: 'daily'
              },
              {
                label: t('pages.DrugDosage.interval.options.weekly'),
                value: 'weekly'
              },
              {
                label: t('pages.DrugDosage.interval.options.monthly'),
                value: 'monthly'
              },
              {
                label: t('pages.DrugDosage.interval.options.whenNeeded'),
                value: 'when_needed'
              },
              {
                label: t('pages.DrugDosage.interval.options.once'),
                value: 'once'
              },
              {
                label: t('pages.DrugDosage.interval.options.hourly'),
                value: 'hourly'
              },
              {
                label: t('pages.DrugDosage.interval.options.unknown'),
                value: 'unknown'
              }
            ]}
          />
        </div>
      </div>

      <NextButton onClick={handleNext} />
    </>
  )
}
export default withTranslation()(DrugDosage)
