import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedCheckBoxGroup from '@bit/medikura.med-lib-uno.med-check-box-group'

import NextButton from 'components/NextButton'
import { labelSort } from 'utils/helper'

import { trackEvent } from 'service/tracker'

const Habits = ({
  handleBlur,
  setFieldValue: handleOnChange,
  values,
  habits,
  setHabits,
  goNextPage,
  t
}) => {
  useEffect(() => trackEvent('PageView - Habits'), [])

  const setAndProceed = event => {
    event.preventDefault()

    trackEvent('Submit - Habits')

    if (habits === values.habits) {
      goNextPage()
    } else {
      setHabits(values.habits, goNextPage)
    }
  }

  // !!!!!!!!!!!!!!!!!!!!!! ATTENTION !!!!!!!!!!!!!!!!!!!!!!
  // Using the localized version for stress + diet is
  // intentional. The backend current doesn't support
  // the values "stress" or "diet".
  //
  // !!! DO NOT CHANGE THIS UNTIL THE BACKEND HAS BEEN ADAPTED !!!

  // :TODO: Use just the keys as values in this form and the releated comments and replace
  // them with the localized version as required in correctHabitsData before sending the content.
  const dataList = [
    {
      label: t('pages.Habits.options.smoking'),
      value: 'smoking'
    },
    {
      label: t('pages.Habits.options.stress'),
      value: t('pages.Habits.options.stress')
    },
    {
      label: t('pages.Habits.options.diet'),
      value: t('pages.Habits.options.diet')
    },
    {
      label: t('pages.Habits.options.drinking'),
      value: 'drinking'
    },
    {
      label: t('pages.Habits.options.sport'),
      value: 'sport'
    }
  ]
  dataList.sort(labelSort)

  return (
    <>
      <MedTypography variant='h2'>
        <Trans i18nKey='pages.Habits.title'>
          <strong />
        </Trans>{' '}
        ({t('common.optional')})
      </MedTypography>
      <MedCheckBoxGroup
        name='habits'
        dataList={dataList}
        textKey='label'
        valueKey='value'
        onChange={handleOnChange}
        onBlur={handleBlur}
        value={values.habits}
        medStyle='med-style-rounded'
      />

      <NextButton onClick={setAndProceed} />
    </>
  )
}
export default withTranslation()(Habits)
