import { getApp } from 'utils/helper'
import config from '../config'

const trackMatomoEvent = (category, action, label, value) => {
  if (config.MATOMO_ENV) {
    window._paq = window._paq || []
    window._paq.push(['trackEvent', category, action, label, value])
  } else {
    console.warn('Matomo not configured - ignoring event', {
      'matomo-event-category': category,
      'matomo-event-action': action,
      'matomo-event-label': label,
      'matomo-event-value': value
    })
  }
}

let category = 'widget-v3'
switch (getApp()) {
  case 'seriousnesschecker':
    category = 'seriousnesschecker'
    break
}

const trackedFields = []
const trackEvent = (action = '', label = '', repeated = true, value = 1) => {
  if (repeated || !trackedFields.includes(action)) {
    if (!trackedFields.includes(action)) {
      trackedFields.push(action)
    }

    return trackMatomoEvent(category, action, label, value)
  }
  return null
}

export { trackEvent }
