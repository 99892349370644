export default {
  EVENTSTORE_BASE_URL: process.env.REACT_APP_EVENTSTORE_BASE_URL,
  AUTOCOMPLETE_BASE_URL: process.env.REACT_APP_AUTOCOMPLETE_BASE_URL,
  AUTOCOMPLETE_SEARCH_LIST_SIZE: 30,
  INSIGHTS_BASE_URL: process.env.REACT_APP_INSIGHTS_BASE_URL + '/insights',
  IDENTITY_BASE_URL: process.env.REACT_APP_IDENTITY_BASE_URL,
  MATOMO_URL: process.env.REACT_APP_MATOMO_URL,
  MATOMO_ENV: process.env.REACT_APP_MATOMO_ENV,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
  REACT_APP_CI_COMMIT_REF_NAME: process.env.REACT_APP_CI_COMMIT_REF_NAME,
  NODE_ENV: process.env.NODE_ENV,
  PHONE_MAIL_PREFIX: process.env.REACT_APP_PHONE_MAIL_PREFIX,
  PHONE_MAIL_POSTFIX: process.env.REACT_APP_PHONE_MAIL_POSTFIX,
  ONE_YEAR_OLD_MAX_HEIGHT: 120,
  ONE_YEAR_OLD_MAX_WEIGHT: 20,
  COVID_VACCINE_PREFIX: 'J07BX03',
  COVID_VACCINES: {
    vaxzevria: 'J07BX03VECAZ',
    johnson: 'J07BX03VECJJ',
    moderna: 'J07BX03RNAMOD',
    biontech: 'J07BX03RNABNT'
  },
  COVID_VACCINE_DOSES: 3,
  COVID_VACCINE_SIDEEFFECT_MONTH: 2
}
