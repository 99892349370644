import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import config from '../config'
import { getStringParamFromRootData } from 'utils/params'
import * as Sentry from '@sentry/browser'

const searchSideEffects = (searchText, language = 'de') => {
  return new Promise((resolve, reject) => {
    return axios
      .get(
        `${config.AUTOCOMPLETE_BASE_URL}/effect/${encodeURIComponent(
          searchText
        )}?lang=${language}&size=${config.AUTOCOMPLETE_SEARCH_LIST_SIZE}`
      )
      .then(response => {
        if (response.data && response.data.length >= 0) {
          resolve(response.data)
        }
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
        reject(error)
      })
  })
}

const searchDrugs = (searchText, countryCode = 'de') => {
  return new Promise((resolve, reject) => {
    return axios
      .get(
        `${
          config.AUTOCOMPLETE_BASE_URL
        }/medicine/${countryCode}/${encodeURIComponent(searchText)}?size=${
          config.AUTOCOMPLETE_SEARCH_LIST_SIZE
        }`
      )
      .then(response => {
        if (response.data && response.data.items.length >= 0) {
          resolve(response.data.items)
        }
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
        reject(error)
      })
  })
}

const _getRequestHeaders = () => {
  const appId = getStringParamFromRootData('appId')
  const sourceDomain = getStringParamFromRootData('sourceDomain')
  const headers = {
    'Content-Type': 'application/json',
    'X-API-Version': '3.0.0',
    'X-Event-Version': '3.0.0',
    'X-Event-App-Created': appId.length
      ? 'med-app-capture:' + appId
      : 'med-app-capture',
    'X-Event-Role': 'patient'
  }
  if (sourceDomain.length) {
    headers['X-Event-Referrer'] = sourceDomain
  }

  return headers
}

const createReport = async reportData => {
  if (reportData === undefined) {
    throw new Error('reportData is missing.')
  }

  const reportId = uuidv4()
  const response = await axios.post(
    `${config.EVENTSTORE_BASE_URL}/report/${reportId}`,
    reportData,
    {
      headers: _getRequestHeaders()
    }
  )
  return response.data.id
}

const processReport = async reportId => {
  if (reportId === undefined) {
    throw new Error('reportId is missing.')
  }

  const response = axios.put(
    `${config.EVENTSTORE_BASE_URL}/streams/report/${reportId}/process`,
    { reportId },
    { headers: _getRequestHeaders() }
  )
  return response
}

const saveReportIdInPatient = async (token, reportId) => {
  if (reportId === undefined) {
    throw new Error('reportId is missing.')
  }

  return axios.post(
    `${config.IDENTITY_BASE_URL}/patient/me/reportId`,
    JSON.stringify({
      reportId
    }),
    {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Version': '1.0.0',
        Authorization: `Bearer ${token}`
      }
    }
  )
}

export {
  searchSideEffects,
  searchDrugs,
  createReport,
  processReport,
  saveReportIdInPatient
}
