import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import PreviousButton from 'components/PreviousButton'
import ProgressBar from 'components/ProgressBar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingBottom: '2rem'
  },
  buttonWrapper: {
    width: '12%',
    display: 'inline-block'
  },
  barWrapper: {
    width: '88%',
    display: 'inline-block'
  },
  fullWidth: {
    width: '100%',
    transition: 'all 1s'
  }
}))

const AppHeader = ({ currentPageIndex, pages, onPreviousPageClick }) => {
  const classes = useStyles()
  const isPreviousButtonEnabled =
    currentPageIndex > 0 &&
    pages[currentPageIndex].category !== 'complete' &&
    pages[currentPageIndex].category !== 'result'

  return (
    <div className={classes.root}>
      {isPreviousButtonEnabled && (
        <div className={classes.buttonWrapper}>
          <PreviousButton onClick={onPreviousPageClick} />
        </div>
      )}
      <div
        className={clsx(
          classes.barWrapper,
          isPreviousButtonEnabled ? '' : classes.fullWidth
        )}
      >
        <ProgressBar currentPageIndex={currentPageIndex} pages={pages} />
      </div>
    </div>
  )
}

export default AppHeader
