import React from 'react'
import clsx from 'clsx'
import { withTranslation } from 'react-i18next'

import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  element: {
    marginRight: theme.spacing(1)
  }
}))

const Footer = ({ className, t }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <Link
          className={classes.element}
          href='https://www.medwatcher.io/datenschutz'
          target='_blank'
        >
          {t('footer.dataPrivacy')}
        </Link>
        <Link
          className={classes.element}
          href='https://www.medwatcher.io/impressum'
          target='_blank'
        >
          {t('footer.imprint')}
        </Link>
      </div>
      <div>© {new Date().getFullYear()} XO Life GmbH</div>
    </div>
  )
}
export default withTranslation()(Footer)
