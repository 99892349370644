import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedCheckBoxGroup from '@bit/medikura.med-lib-uno.med-check-box-group'

import NextButton from 'components/NextButton'
import { labelSort } from 'utils/helper'

import { trackEvent } from 'service/tracker'

const Diagnoses = ({
  handleBlur,
  setFieldValue,
  values,
  diagnoses,
  setDiagnoses,
  goNextPage,
  t
}) => {
  useEffect(() => trackEvent('PageView - Diagnoses'), [])

  const corrections = () => {
    const obj = values.diagnoses.map(item => {
      return {
        name: t(`pages.Diagnoses.options.${item}`),
        class: item
      }
    })
    setFieldValue('diagnosesObject', obj)
  }

  const setAndProceed = event => {
    event.preventDefault()
    corrections()

    trackEvent('Submit - Diagnoses')

    if (diagnoses === values.diagnoses) {
      goNextPage()
    } else {
      setDiagnoses(values.diagnoses, goNextPage)
    }
  }
  const dataList = [
    {
      label: t('pages.Diagnoses.options.bloodPressure'),
      value: 'bloodPressure'
    },
    {
      label: t('pages.Diagnoses.options.diabetes'),
      value: 'diabetes'
    },
    {
      label: t('pages.Diagnoses.options.lipopathy'),
      value: 'lipopathy'
    },
    {
      label: t('pages.Diagnoses.options.cardiac'),
      value: 'cardiac'
    },
    {
      label: t('pages.Diagnoses.options.gastrointestinal'),
      value: 'gastrointestinal'
    },
    {
      label: t('pages.Diagnoses.options.respiratory'),
      value: 'respiratory'
    },
    {
      label: t('pages.Diagnoses.options.kidney'),
      value: 'kidney'
    },
    {
      label: t('pages.Diagnoses.options.cancer'),
      value: 'cancer'
    },
    {
      label: t('pages.Diagnoses.options.skin'),
      value: 'skin'
    },
    {
      label: t('pages.Diagnoses.options.gout'),
      value: 'gout'
    },
    {
      label: t('pages.Diagnoses.options.immune'),
      value: 'immune'
    },
    {
      label: t('pages.Diagnoses.options.rheumatism'),
      value: 'rheumatism'
    },
    {
      label: t('pages.Diagnoses.options.liver'),
      value: 'liver'
    },
    {
      label: t('pages.Diagnoses.options.nerve'),
      value: 'nerve'
    },
    {
      label: t('pages.Diagnoses.options.thyroid'),
      value: 'thyroid'
    },
    {
      label: t('pages.Diagnoses.options.allergy'),
      value: 'allergy'
    }
  ]
  dataList.sort(labelSort)

  return (
    <>
      <MedTypography variant='h2'>
        <Trans i18nKey='pages.Diagnoses.title'>
          <strong />
        </Trans>{' '}
        ({t('common.optional')})
      </MedTypography>
      <MedCheckBoxGroup
        name='diagnoses'
        dataList={dataList}
        textKey='label'
        valueKey='value'
        onChange={setFieldValue}
        onBlur={handleBlur}
        value={values.diagnoses}
        medStyle='med-style-rounded'
      />

      <NextButton onClick={setAndProceed} />
    </>
  )
}
export default withTranslation()(Diagnoses)
