import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    marginBottom: 8,
    paddingTop: 1,
    paddingRight: 0,
    paddingBottom: 1,
    paddingLeft: 0,

    '& svg': {
      fontSize: '1.4em'
    }
  }
}))

const PreviousButton = ({ onClick: handleOnClick }) => {
  const classes = useStyles()
  return (
    <IconButton className={classes.root} onClick={handleOnClick}>
      <KeyboardBackspaceIcon />
    </IconButton>
  )
}

export default PreviousButton
