import React from 'react'
import config from '../config'

class Matomo extends React.Component {
  componentDidMount () {
    if (config.MATOMO_ENV) {
      var _paq = window._paq || []
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView'])
      _paq.push(['enableLinkTracking'])
      ;(function () {
        var u = config.MATOMO_URL
        _paq.push(['setTrackerUrl', u + 'matomo.php'])
        _paq.push(['setSiteId', JSON.parse(config.MATOMO_ENV)])
        const d = document
        const g = d.createElement('script')
        const s = d.getElementsByTagName('script')[0]
        g.type = 'text/javascript'
        g.async = true
        g.defer = true
        g.src = u + 'matomo.js'
        s.parentNode.insertBefore(g, s)
      })()
    }
  }

  render () {
    return null
  }
}

export default Matomo
