import React from 'react'
import * as Sentry from '@sentry/browser'
import { get, cloneDeep } from 'lodash'
import { useStateWithCallbackLazy } from 'use-state-with-callback'

import { createReport, processReport, saveReportIdInPatient } from 'service'

import Wizard from 'components/Wizard'
import SeriousnessCheckerDisclaimer from 'pages/SeriousnessCheckerDisclaimer'
import SideEffect from 'pages/SideEffect'
import SideEffectTimeFrame from 'pages/SideEffectTimeFrame'
import CovidVaccines from 'pages/CovidVaccines'
import Drugs from 'pages/Drugs'
import SuspectedDrugs from 'pages/SuspectedDrugs'
import CovidVaccineInfo from 'pages/CovidVaccineInfo'
import DrugTimeFrame from 'pages/DrugTimeFrame'
import DrugDosage from 'pages/DrugDosage'
import Gender from 'pages/Gender'
import Body from 'pages/Body'
import Habits from 'pages/Habits'
import Diagnoses from 'pages/Diagnoses'
import DiagnoseComments from 'pages/DiagnoseComments'
import HabitComments from 'pages/HabitComments'
import Seriousness from 'pages/Seriousness'
import Comment from 'pages/Comment'
import Email from 'pages/Email'
import Thanks from 'pages/Thanks'
import Result from 'pages/Result'

import reportValidationSchema from './reportValidations'
import { getReportPayload } from './reportPayload'
import { trackEvent } from 'service/tracker'
import { isCovidVaccine, filterPossibleSuspiciousDrugs } from 'utils/helper'
import { getStringParamFromRootData } from 'utils/params'

import {
  REPORT_SUCCESSFUL,
  REPORT_ERROR_DENIED,
  REPORT_ERROR_NOCONNECTION
} from '../../constants'

const ReportWizard = props => {
  const [sideEffects, setSideEffects] = useStateWithCallbackLazy([])
  const [covidVaccines, setCovidVaccines] = useStateWithCallbackLazy([])
  const [drugs, setDrugs] = useStateWithCallbackLazy([])
  const [diagnoses, setDiagnoses] = useStateWithCallbackLazy([])
  const [habits, setHabits] = useStateWithCallbackLazy([])

  const handleSubmit = (values, actions) => {
    // Work with copies so any alteration doesn't change the original form
    // which would make a resend impossible
    const reportData = prepareForSubmission(
      cloneDeep(values),
      cloneDeep(actions)
    )
    submitReportCreation(reportData, actions)
  }

  const prepareForSubmission = (values, actions) => {
    actions.setSubmitting(true)
    return getReportPayload(values)
  }

  const submitReportCreation = async (formData, actions) => {
    try {
      const patientToken = getStringParamFromRootData('patientToken')
      const reportId = await createReport(formData)
      await processReport(reportId)
      if (patientToken) {
        await saveReportIdInPatient(patientToken, reportId)
      }
      trackEvent('Report Created', '', false)
      actions.setStatus(REPORT_SUCCESSFUL)
    } catch (error) {
      // TODO :: Discuss the scenarios for report creation failure and how to handle the user experience
      if (error.message === 'Network Error') {
        actions.setStatus(REPORT_ERROR_NOCONNECTION)
      } else {
        actions.setStatus(REPORT_ERROR_DENIED)
      }

      Sentry.withScope(scope => {
        if (error.response !== undefined && error.response.data !== undefined) {
          const responseData = error.response.data
          if (responseData.errors && responseData.errors.errors) {
            for (const error of responseData.errors.errors) {
              if (error.dataPath && !error.dataPath.includes('.identity.')) {
                let path = error.dataPath
                if (path.indexOf('.') === 0) {
                  path = path.substring(1)
                }
                const debugInfo = get(formData, path, null)
                if (debugInfo !== null) {
                  error.debugInfo = debugInfo
                }
              }
            }
          }
          scope.setExtra('data', JSON.stringify(responseData))
        }
        Sentry.captureException(error)
      })
    }
    actions.setSubmitting(false)
  }

  return (
    <Wizard
      initialValues={props.reportTemplate}
      onSubmit={handleSubmit}
      validationSchema={reportValidationSchema}
    >
      <Wizard.Page filterApp='seriousnesschecker'>
        <SeriousnessCheckerDisclaimer />
      </Wizard.Page>
      <Wizard.Page category='sideEffects'>
        <SideEffect sideEffects={sideEffects} setSideEffects={setSideEffects} />
      </Wizard.Page>
      {sideEffects.map((item, index) => [
        <Wizard.Page
          key={index + '-00'}
          category='sideEffects'
          validate={`effects[${index}]`}
        >
          <SideEffectTimeFrame
            itemIndex={index}
            setSideEffects={setSideEffects}
          />
        </Wizard.Page>
      ])}

      <Wizard.Page category='drugs' validate='temp.vaccines.covid' noProgress>
        <CovidVaccines
          covidVaccines={covidVaccines}
          setCovidVaccines={setCovidVaccines}
        />
      </Wizard.Page>
      <Wizard.Page category='drugs'>
        <Drugs setDrugs={setDrugs} />
      </Wizard.Page>
      {filterPossibleSuspiciousDrugs(sideEffects, covidVaccines, drugs, 'drug')
        .length > 1 && (
        <Wizard.Page category='drugs' validate='drugs'>
          <SuspectedDrugs drugs={drugs} setDrugs={setDrugs} />
        </Wizard.Page>
      )}
      {drugs.map(item =>
        item.drug.prefilled
          ? []
          : isCovidVaccine(item.drug)
          ? [
              <Wizard.Page
                key={item.index}
                category='drugs'
                validate={`drugs[${item.index}]`}
              >
                <CovidVaccineInfo itemIndex={item.index} />
              </Wizard.Page>
            ]
          : [
              <Wizard.Page
                key={item.index + '-00'}
                category='drugs'
                validate={`drugs[${item.index}]`}
              >
                <DrugTimeFrame itemIndex={item.index} />
              </Wizard.Page>,
              <Wizard.Page
                key={item.index + '-01'}
                category='drugs'
                validate={`drugs[${item.index}]`}
              >
                <DrugDosage itemIndex={item.index} />
              </Wizard.Page>
            ]
      )}
      <Wizard.Page category='patient'>
        <Gender />
      </Wizard.Page>
      <Wizard.Page category='patient' validate='contacts'>
        <Body />
      </Wizard.Page>
      <Wizard.Page category='patient'>
        <Diagnoses diagnoses={diagnoses} setDiagnoses={setDiagnoses} />
      </Wizard.Page>
      {diagnoses.map((item, index) => [
        <Wizard.Page key={index} category='patient' noProgress>
          <DiagnoseComments item={item} />
        </Wizard.Page>
      ])}
      <Wizard.Page category='patient'>
        <Habits habits={habits} setHabits={setHabits} />
      </Wizard.Page>
      {habits.map((item, index) => [
        <Wizard.Page key={index} category='patient' noProgress>
          <HabitComments item={item} />
        </Wizard.Page>
      ])}
      <Wizard.Page category='patient' filterApp='seriousnesschecker'>
        <Seriousness />
      </Wizard.Page>
      <Wizard.Page category='patient'>
        <Comment />
      </Wizard.Page>
      <Wizard.Page category='contact' validate='contacts'>
        <Email />
      </Wizard.Page>
      <Wizard.Page category='complete'>
        <Thanks />
      </Wizard.Page>
      <Wizard.Page category='result'>
        <Result />
      </Wizard.Page>
    </Wizard>
  )
}
export default ReportWizard
