// This template defines the name attribute of the form fields used for
// report creation payload. This object also holds the initial/default values
// for the field. The idea is that we define and maintain all the fields needed for
// the form here.
export default {
  effects: [],
  drugs: [],
  suspectedDrugs: [],
  primaryDrug: '',
  contacts: {
    responsibleAuthority: {
      country: 'de'
    },
    patient: {
      gender: '',
      pregnancy: {
        pregnant: '',
        week: ''
      },
      height: '',
      weight: '',
      dateOfBirthYear: ''
    },
    reporter: {
      identity: {
        email: '',
        phone: ''
      }
    }
  },
  checkInformHealthProfessional: false,
  description: '',
  diagnoses: [],
  diagnoseComments: {},
  habits: [],
  habitComments: {},
  legal: {
    checkTermsAndConditions: true
  },
  temp: {
    vaccines: {
      covid: []
    },
    email: {
      usePhone: false
    }
  }
}
