import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { range, has } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'

import NextButton from 'components/NextButton'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  },
  dropDownWrapper: {
    paddingBottom: theme.spacing(1)
  }
}))

const Body = ({ handleChange, handleBlur, values, errors, goNextPage, t }) => {
  const classes = useStyles()
  useEffect(() => trackEvent('PageView - Body'), [])

  const isHeightError = has(errors, 'contacts.patient.height')
  const isWeightError = has(errors, 'contacts.patient.weight')

  const setAndProceed = event => {
    event.preventDefault()
    trackEvent('Submit - Body')
    goNextPage()
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.Body.title'>
            <strong />
          </Trans>
        </MedTypography>
        <div className={classes.dropDownWrapper}>
          <MedDropDown
            name='contacts.patient.dateOfBirthYear'
            label={t('pages.Body.birthyear.label') + ' *'}
            dataList={range(new Date().getFullYear(), 1901)}
            placeholder={t('pages.Body.birthyear.placeholder')}
            autoNativeOnMobile
            onChange={handleChange}
            onBlur={handleBlur}
            scrollTo={1970}
            autoFocus
            value={values.contacts.patient.dateOfBirthYear}
          />
        </div>
        <div className={classes.dropDownWrapper}>
          <MedDropDown
            name='contacts.patient.height'
            label={
              t('pages.Body.height.label') + ' (' + t('common.optional') + ')'
            }
            dataList={range(1, 250)}
            adornmentText={t('pages.Body.height.adornment')}
            placeholder={t('pages.Body.height.placeholder')}
            autoNativeOnMobile
            onChange={handleChange}
            onBlur={handleBlur}
            scrollTo={170}
            value={values.contacts.patient.height}
            error={isHeightError}
            helperText={isHeightError ? t('pages.Body.height.errorText') : ''}
          />
        </div>
        <div>
          <MedDropDown
            name='contacts.patient.weight'
            label={
              t('pages.Body.weight.label') + ' (' + t('common.optional') + ')'
            }
            dataList={range(1, 300)}
            adornmentText={t('pages.Body.weight.adornment')}
            placeholder={t('pages.Body.weight.placeholder')}
            autoNativeOnMobile
            onChange={handleChange}
            onBlur={handleBlur}
            scrollTo={70}
            value={values.contacts.patient.weight}
            error={isWeightError}
            helperText={isWeightError ? t('pages.Body.weight.errorText') : ''}
          />
        </div>
      </div>
      <NextButton
        disabled={
          !values.contacts.patient.dateOfBirthYear ||
          isHeightError ||
          isWeightError
        }
        onClick={setAndProceed}
      />
    </>
  )
}
export default withTranslation()(Body)
