import React, { useState, useEffect } from 'react'
import { has } from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import Link from '@material-ui/core/Link'

import MedDialog from '@bit/medikura.med-lib-uno.med-dialog'
import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import InfoTooltip from 'components/InfoTooltip'
import NextButton from 'components/NextButton'

import { REPORT_SUCCESSFUL, REPORT_ERRORS } from '../../constants'
import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'inline-block',
    fontSize: '1.3em',
    lineHeight: '1em',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  }
}))

const Email = ({
  ab,
  values,
  errors,
  touched,
  isSubmitting,
  status,
  goNextPage,
  handleChange,
  handleBlur,
  t
}) => {
  const classes = useStyles()

  const isError = has(errors, 'contacts.reporter.identity.email')

  const isTouched = has(touched, 'contacts.reporter.identity.email')

  const isEmpty = values.contacts.reporter.identity.email === ''

  useEffect(() => trackEvent('PageView - Email'), [])

  useEffect(() => {
    if (isTouched && isError && !isEmpty) {
      trackEvent('ErrorWrongEmail - Email')
    }
  }, [isError, isTouched, isEmpty])

  useEffect(() => {
    if (
      !isSubmitting &&
      (status === REPORT_SUCCESSFUL || REPORT_ERRORS.includes(status))
    ) {
      trackEvent('Submit - Email')
      goNextPage()
    }
  }, [isSubmitting, status, goNextPage])

  const [
    isConsentDeclarationModalOpen,
    setConsentDeclarationModalOpen
  ] = useState(false)

  return (
    <>
      <MedTypography variant='h2'>
        {t('pages.Email.emailTitle')}
        {/* ab === 'b' && (
          <InfoTooltip
            paragraphs={[
              t('pages.Email.tooltip.paragraph1'),
              t('pages.Email.tooltip.paragraph2')
            ]}
            page='Email'
          />
        ) */}
      </MedTypography>
      <MedTextField
        name='contacts.reporter.identity.email'
        type='email'
        label={t('pages.Email.email')}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
        required
        value={values.contacts.reporter.identity.email}
        error={isTouched && isError && !isEmpty}
        helperText={
          isTouched && isError && !isEmpty
            ? t('pages.Email.emailErrorText')
            : t('pages.Email.emailHelperText')
        }
      />
      {/* ab !== 'b' && (
        <MedTypography variant='body1'>
          <span className={classes.icon}>✉️</span>
          {t('pages.Email.infoText1')}
          <br />
          <span className={classes.icon}>🔑</span>
          {t('pages.Email.infoText2')}
          <br />
          <span className={classes.icon}>🤝</span>
          {t('pages.Email.infoText3')}
        </MedTypography>
      ) */}
      <MedTypography variant='body1'>
        <span className={classes.icon}>✉️</span>
        {t('pages.Email.infoText1')}
        <br />
        <span className={classes.icon}>🔑</span>
        {t('pages.Email.infoText2')}
        <br />
        <span className={classes.icon}>🤝</span>
        {t('pages.Email.infoText3')}
      </MedTypography>
      <MedTypography variant='body1'>
        <Trans i18nKey='pages.Email.agree'>
          <Link href='https://www.medwatcher.io/datenschutz' target='_blank' />
          <Link
            href='#'
            onClick={() => {
              setConsentDeclarationModalOpen(true)
            }}
          />
        </Trans>
      </MedTypography>
      <MedDialog
        open={isConsentDeclarationModalOpen}
        title={t('pages.Email.consentDeclarationModal.title')}
        onClose={() => {
          setConsentDeclarationModalOpen(false)
        }}
      >
        <MedTypography variant='body1'>
          {t('pages.Email.consentDeclarationModal.body')}
        </MedTypography>
      </MedDialog>

      <NextButton
        label={t('pages.Email.next')}
        disabled={isEmpty || isError || isSubmitting}
        progress={isSubmitting}
      />
    </>
  )
}

export default withTranslation()(Email)
