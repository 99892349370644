import React from 'react'

import MedButton from '@bit/medikura.med-lib-uno.med-button'
import { makeStyles } from '@material-ui/core/styles'

import { getStringParamFromRootData } from 'utils/params'

const useStyles = makeStyles(theme => ({
  successButton: {
    display: 'block',
    margin: theme.spacing(0, 'auto', 2)
  }
}))

const ConditionalSuccessButton = () => {
  const classes = useStyles()
  const successButtonCaption = getStringParamFromRootData(
    'successButtonCaption'
  )
  const successButtonPath = getStringParamFromRootData('successButtonPath')
  if (successButtonCaption && successButtonPath) {
    return (
      <MedButton
        className={classes.successButton}
        onClick={() => {
          window.location.href = successButtonPath
        }}
      >
        {successButtonCaption}
      </MedButton>
    )
  } else {
    return null
  }
}

export default ConditionalSuccessButton
