import React from 'react'
import * as Sentry from '@sentry/browser'

import config from '../../config'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    // Initialize Sentry
    if (config.SENTRY_DSN) {
      Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENV,
        ...(config.NODE_ENV !== 'development' && {
          release: 'med-app-capture@' + config.REACT_APP_CI_COMMIT_REF_NAME
        })
      })
    }
  }

  componentDidCatch (error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render () {
    return this.props.children
  }
}

export default ErrorBoundary
