import React, { useState, useCallback, useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { debounce } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import MedSearchBox from '@bit/medikura.med-lib-uno.med-search-box'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import NextButton from 'components/NextButton'
import InfoTooltip from 'components/InfoTooltip'

import { searchSideEffects } from 'service'
import { handleOnSearch, handleOnSelect } from 'utils/autocomplete'
import {
  highlight,
  extractChipsFromAutoCompleteText,
  searchBoxFilterNone
} from 'utils/helper'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  }
}))

const SideEffect = props => {
  const classes = useStyles()

  const [sideEffectsList, setSideEffectsList] = useState([])

  useEffect(() => trackEvent('PageView - SideEffect'), [])

  // TODO: Discuss and decide on 'shall we' and 'how to' show this API error
  // const [networkOrApiError, setNetworkOrApiError] = useState(false)

  // Handler for onChange of input field
  const handleOnSearchEffect = useCallback(
    debounce(searchText => {
      trackEvent('AutoComplete - SideEffect', '', false)
      handleOnSearch(
        search => searchSideEffects(search, props.i18n.language),
        searchText,
        'name',
        { name: searchText, isSerious: '' },
        setSideEffectsList
      )
    }, 300),
    []
  )

  // Handler called when an effect is added to the tags, i.e. either selected from the list or free text entered
  const handleOnSelectEffect = (value, item) => {
    setSideEffectsList([])
    handleOnSelect(value, item, setSideEffectsList, props, 'effects')
  }

  const filterInputValue = value => {
    const { chips, remainingText } = extractChipsFromAutoCompleteText(value)

    if (!remainingText.trim()) {
      setSideEffectsList([])
    }

    if (!chips) {
      return value
    }

    const newChips = []
    for (const chip of chips) {
      newChips.push({
        name: chip,
        isSerious: ''
      })
    }
    props.setFieldValue(`effects`, [...props.values.effects, ...newChips])

    trackEvent('AutoChip - SideEffect', '', false)

    return remainingText
  }

  const corrections = () => {
    // Before going to next page, check and set free text
    props.values.effects.forEach((effect, index) => {
      if (typeof effect === 'string') {
        const item = {
          name: effect,
          isSerious: ''
        }
        props.setFieldValue(`effects[${index}]`, item)
      }
    })
  }

  const handleNext = event => {
    event.preventDefault()

    trackEvent('Submit - SideEffect', '' + props.values.effects.length)

    corrections()
    if (props.sideEffects === props.values.effects) {
      props.goNextPage()
    } else {
      props.setSideEffects(props.values.effects, props.goNextPage)
    }
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.SideEffect.heading'>
            <strong />
          </Trans>
          {/* props.ab === 'b' && (
            <InfoTooltip
              paragraphs={[
                props.t('pages.SideEffect.tooltip.paragraph1'),
                props.t('pages.SideEffect.tooltip.paragraph2')
              ]}
              page='SideEffect'
            />
          ) */}
        </MedTypography>
        <MedSearchBox
          chips
          freeText
          isAutoFocusEnabled
          inputId='effectsSearch'
          placeholder={props.t('pages.SideEffect.searchPlaceholder')}
          label={props.t('pages.SideEffect.searchLabel')}
          dataList={sideEffectsList}
          filterList={searchBoxFilterNone}
          value={props.values.effects}
          onChange={handleOnSearchEffect}
          onSelect={handleOnSelectEffect}
          filterInputValue={filterInputValue}
          renderOption={(option, state) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                {highlight(option.name, state.inputValue)}
              </div>
            )
          }}
          variant='filled'
          optionLabelKey='name'
        />
      </div>
      <NextButton
        disabled={!(props.values.effects && props.values.effects.length)}
        onClick={handleNext}
      />
    </>
  )
}
export default withTranslation()(SideEffect)
