import React from 'react'
import PropTypes from 'prop-types'
import IdleTimer from 'react-idle-timer'

import MedTooltip from '@bit/medikura.med-lib-uno.med-tooltip'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as InfoIcon } from 'assets/info.svg'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  tooltip: {
    background: '#666'
  },
  iconWrapper: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
    outline: 'none'
  },
  icon: {
    cursor: 'help',
    width: '1em',
    height: '1em',
    verticalAlign: 'middle'
  }
}))

const InfoTooltip = ({ paragraphs, page }) => {
  const classes = useStyles()
  const [iconFocused, setIconFocused] = React.useState(false)
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const [hadManualTooltip, setHadManualTooltip] = React.useState(false)

  const titleParagraphs = []
  for (const paragraph of paragraphs) {
    titleParagraphs.push(
      <MedTypography variant='body2'>{paragraph}</MedTypography>
    )
  }

  return (
    <>
      <MedTooltip
        classes={{
          tooltip: classes.tooltip
        }}
        title={<>{titleParagraphs}</>}
        placement='bottom'
        open={iconFocused || tooltipOpen}
        onOpen={() => {
          if (tooltipOpen === true) {
            return
          }
          setHadManualTooltip(true)
          setTooltipOpen(true)
          trackEvent('InfoTooltip - Manual Open - ' + page)
        }}
        onClose={() => {
          if (tooltipOpen === false) {
            return
          }
          setTooltipOpen(false)
          trackEvent('InfoTooltip - Manual Close - ' + page)
        }}
      >
        <div
          tabIndex='0'
          className={classes.iconWrapper}
          onFocus={() => {
            setHadManualTooltip(true)
            setIconFocused(true)
          }}
          onBlur={() => {
            setIconFocused(false)
          }}
        >
          <InfoIcon className={classes.icon} />
        </div>
      </MedTooltip>
      <IdleTimer
        timeout={20000}
        onIdle={() => {
          if (hadManualTooltip || tooltipOpen === true) {
            return
          }
          setTooltipOpen(true)
          trackEvent('InfoTooltip - Idle Open - ' + page)
        }}
        onAction={() => {
          if (hadManualTooltip || tooltipOpen === true) {
            return
          }
          setTooltipOpen(false)
          trackEvent('InfoTooltip - Idle Close - ' + page)
        }}
        events={[
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mousewheel',
          'mousedown',
          'touchstart',
          'MSPointerDown',
          'visibilitychange'
        ]}
      />
    </>
  )
}

InfoTooltip.propTypes = {
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  page: PropTypes.string.isRequired
}

export default InfoTooltip
