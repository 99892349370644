import queryString from 'query-string'

const getRootElement = () => {
  return document.getElementById('medikuraCaptureApp')
}

const getBooleanParamFromURL = (url, queryParameter) => {
  const parsed = queryString.parse(url)
  return parsed[queryParameter] ? parsed[queryParameter] === 'true' : false
}

const getStringParamFromURL = (url, queryParameter, fallback = '') => {
  const parsed = queryString.parse(url)
  return parsed[queryParameter] ? parsed[queryParameter] : fallback
}

const getStringParamFromRootData = (queryParameter, fallback = '') => {
  const rootElement = getRootElement()
  if (rootElement.dataset && rootElement.dataset[queryParameter]) {
    return rootElement.dataset[queryParameter]
  } else {
    return getStringParamFromURL(
      window.location.search,
      queryParameter,
      fallback
    )
  }
}

export {
  getStringParamFromURL,
  getBooleanParamFromURL,
  getStringParamFromRootData,
  getRootElement
}
