import React from 'react'
import I18n from 'i18n'
import { get } from 'lodash'

import Hypher from 'hypher'
import hypherEnglish from 'hyphenation.en-us'
import hypherGerman from 'hyphenation.de'

import config from '../../config'

const getApp = () => {
  const paths = window.location.pathname.replace(/^\/+|\/+$/g, '').split('/')
  if (paths.length > 0) {
    switch (paths.pop()) {
      case 'seriousnesschecker':
        return 'seriousnesschecker'
      default:
        return ''
    }
  } else {
    return ''
  }
}

const highlight = (haystack, needle) => {
  if (needle.length === 0) {
    return <>{haystack}</>
  }
  const lowerHaystack = haystack.toLowerCase()
  const lowerNeedle = needle.toLowerCase()
  const result = []
  let start = 0
  for (
    let indexOf;
    (indexOf = lowerHaystack.indexOf(lowerNeedle, start)) !== -1;

  ) {
    result.push(<strong>{haystack.substring(start, indexOf)}</strong>)
    result.push(<>{haystack.substring(indexOf, indexOf + needle.length)}</>)
    start = indexOf + needle.length
  }
  result.push(<strong>{haystack.substring(start)}</strong>)
  return result
}

const isCovidVaccine = item => {
  return (
    item.atc_international &&
    item.atc_international.startsWith(config.COVID_VACCINE_PREFIX)
  )
}
const isLeapYear = y => !(y % 4 || (!(y % 100) && y % 400))

const getLocalizedEnumeration = number => {
  switch (number) {
    case 1:
      return number + I18n.t('common.enumeration.first')
    case 2:
      return number + I18n.t('common.enumeration.second')
    case 3:
      return number + I18n.t('common.enumeration.third')
    default:
      return number + I18n.t('common.enumeration.above')
  }
}

const extractChipsFromAutoCompleteText = text => {
  let lastEntry = null
  const chips = []
  for (const entry of text.split(',')) {
    if (lastEntry === null) {
      lastEntry = entry
      continue
    }

    // Merge if last entry ends with a number and the next entry doesn't start with a non-numeric character
    if (
      lastEntry.match(/[0-9]$/g) &&
      (entry === '' || entry.match(/^[0-9]/g))
    ) {
      lastEntry += ',' + entry
    } else {
      // String has still open brackets which need to be closed
      if (lastEntry.split('(').length > lastEntry.split(')').length) {
        lastEntry += ',' + entry
      } else {
        lastEntry = lastEntry.trim()
        if (lastEntry) {
          chips.push(lastEntry)
        }
        lastEntry = entry
      }
    }
  }

  return {
    chips,
    remainingText: lastEntry
  }
}

const searchBoxFilterNone = (items, value, options = {}) => {
  return items
}

const labelSort = (object1, object2) => {
  return object1.label.localeCompare(object2.label, undefined, {
    sensitivity: 'accent'
  })
}

let localizedHypher = null
switch (I18n.language) {
  case 'de':
    localizedHypher = new Hypher(hypherGerman)
    break
  default:
    localizedHypher = new Hypher(hypherEnglish)
}

const hyphenatedText = Input => {
  // Soft hyphen is in the ''
  return localizedHypher.hyphenate(Input).join('­')
}

const convertToDate = dateObj => {
  return new Date(dateObj.yyyy, dateObj.mm - 1, dateObj.dd || 1)
}

const convertToPhonenumber = phoneString => {
  if (phoneString.startsWith('+')) {
    phoneString = '00' + phoneString.slice(1)
  }
  phoneString = phoneString.replace(/[^\d]/g, '')
  if (phoneString.startsWith('0049')) {
    phoneString = '0' + phoneString.slice(4)
  }
  return phoneString
}

const filterPossibleSuspiciousDrugs = (
  effects,
  covidVaccines,
  drugs,
  drugPath = null
) => {
  if (effects.length === 0) {
    return drugs
  }

  const minDate = effects.reduce((previousValue, value) => {
    if (!value.startDate) {
      return previousValue
    }
    return Math.min(previousValue, convertToDate(value.startDate).getTime())
  }, new Date().getTime())

  if (drugPath) {
    drugs = drugs.map(item => get(item, drugPath))
  }

  return [
    ...drugs.filter(
      drug =>
        !drug.startDate ||
        !drug.prefilled ||
        convertToDate(drug.startDate).getTime() <= minDate
    ),
    ...covidVaccines.filter(
      vaccine =>
        vaccine.type !== 'none' && convertToDate(vaccine.date) <= minDate
    )
  ]
}

const getCovidVaccineName = vaccine =>
  (vaccine.type === 'other'
    ? vaccine.custom
    : I18n.t('common.vaccines.covid.' + vaccine.type)) +
  ' (' +
  getLocalizedEnumeration(vaccine.dose) +
  ' ' +
  I18n.t('pages.CovidVaccines.doseEnd') +
  ')'

export {
  getApp,
  extractChipsFromAutoCompleteText,
  highlight,
  isCovidVaccine,
  isLeapYear,
  getLocalizedEnumeration,
  searchBoxFilterNone,
  labelSort,
  hyphenatedText,
  convertToDate,
  convertToPhonenumber,
  filterPossibleSuspiciousDrugs,
  getCovidVaccineName
}
