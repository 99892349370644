import { array, object, string, number } from 'yup'
import { get } from 'lodash'
import { dateShape } from 'utils/validation'

const DrugsSchema = {
  drugs: array().of(
    object().shape({
      medicine: string().required(),

      startDate: dateShape
        .isFuture('dateShouldNotBeInFuture')
        .isBeforeEffects('drugStartDateShouldBeBeforeEffectStartDate'),

      endDate: object().when('ongoing', {
        is: true,
        then: object()
          .nullable()
          .notRequired(),
        otherwise: dateShape
          .isBefore('endDateShouldBeAfterStartDate')
          .isFuture('dateShouldNotBeInFuture')
          .isBeforeEffects('drugStartDateShouldBeBeforeEffectStartDate')
      }),

      dose: object().shape({
        amount: number()
          .nullable()
          .transform(function (value, originalValue) {
            return originalValue === '' ? null : value
          })
          .test('requires-amount', 'Amount required', function (value) {
            if (value) {
              return true
            }
            const { path, createError, options } = this
            const prefix = path.substring(0, path.lastIndexOf('.'))
            return (
              !get(options.context, prefix + '.interval', '') &&
              !get(options.context, prefix + '.unit', '')
            )
          })
          .min(1)
          .integer()
          .test('is-positive-integer', 'Invalid integer', value => {
            if (value && value.length) {
              return (value + '').match(/^\d*$/)
            } else {
              return true
            }
          })
      })
    })
  )
}

export default DrugsSchema
