import React, { useEffect, useState } from 'react'
import I18n from 'i18n'
import { find } from 'lodash'
import { withTranslation, Trans } from 'react-i18next'

import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import MedButton from '@bit/medikura.med-lib-uno.med-button'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import CircularProgress from '@material-ui/core/CircularProgress'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { ReactComponent as ReportFailedIcon } from 'assets/report-failed.svg'
import { ReactComponent as VaccineAlertImage } from 'assets/vaccine-alert.svg'
import NextButton from 'components/NextButton'
import ConditionalSuccessButton from 'components/ConditionalSuccessButton'
import { getStringParamFromRootData } from 'utils/params'
import { REPORT_SUCCESSFUL, REPORT_ERROR_NOCONNECTION } from '../../constants'

import BannerMedWatcherLarge from 'components/BannerMedWatcherLarge'

import { trackEvent } from 'service/tracker'
import { getEffects } from 'service/statistics'

import config from '../../config'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    padding: theme.spacing(2, 0, 1)
  },
  paragraphWrapper: {
    maxWidth: '460px',
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(1, 0)
  },
  chartWrapper: {
    background: '#FFFCFA',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    margin: theme.spacing(1, 1, 2)
  },
  progress: {
    display: 'block',
    margin: theme.spacing(0, 'auto'),
    color: theme.palette.secondary.main
  },
  vaccineWarning: {
    border: '3px solid #F9EFC7',
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  vaccineWarningTitle: {
    color: '#775433',
    background: '#F9EFC7',
    borderRadius: theme.spacing(2, 2, 0, 0),
    '& svg': {
      width: '1.5em',
      height: '1.5em',
      verticalAlign: 'middle',
      marginRight: theme.spacing(1)
    }
  },
  vaccineWarningText: {
    padding: theme.spacing(2)
  },
  reportFailedIcon: {
    display: 'block',
    height: '6rem',
    width: '6rem',
    margin: theme.spacing(0, 'auto', 2)
  }
}))

const Thanks = ({ isSubmitting, status, goNextPage, values, t }) => {
  const classes = useStyles()
  const appMode = getStringParamFromRootData('mode', 'default')
  const [showResultsButton, setShowResultsButton] = useState(false)
  const [isLoadingEffects, setIsLoadingEffect] = useState(false)

  useEffect(() => {
    for (const drug of values.drugs) {
      if (drug.atc_international) {
        setIsLoadingEffect(true)
        getEffects(drug.atc_international, I18n.language)
          .then(response => {
            for (const reportedSideEffect of values.effects) {
              for (const sideEffect of response.sideEffects) {
                const ptCodes = sideEffect.ptCodes
                  ? sideEffect.ptCodes
                  : [sideEffect.ptCode]

                if (ptCodes.includes(reportedSideEffect.pt_code)) {
                  setShowResultsButton(true)
                }
              }
            }
          })
          .finally(() => setIsLoadingEffect(false))
      }
    }
  }, [values])

  const handleNext = event => {
    event.preventDefault()
    trackEvent('Submit - Thanks')
    goNextPage()
  }

  if (status === REPORT_SUCCESSFUL) {
    const sourceDomain = getStringParamFromRootData('sourceDomain')
    const patientToken = getStringParamFromRootData('patientToken')

    const content =
      sourceDomain === 'data4life.care' ? (
        <div className={classes.paragraphWrapper}>
          <MedTypography variant='body1' align='center'>
            {t('pages.Thanks.data4Life.text')}
          </MedTypography>
          <MedTypography variant='body1' align='center'>
            <Link
              href='https://www.data4life.care/de/studien/ecov/'
              target='_blank'
            >
              {t('pages.Thanks.data4Life.link')}
            </Link>
          </MedTypography>
        </div>
      ) : sourceDomain === 'medwatcher.io' && !patientToken ? (
        <div className={classes.paragraphWrapper}>
          <MedTypography variant='body1'>
            {t('pages.Thanks.thankYouParagraph')}
          </MedTypography>
          <MedTypography variant='h2' align='center'>
            {t('pages.Thanks.nextSteps.title')}
          </MedTypography>
          <MedTypography variant='body1'>
            <Trans i18nKey='pages.Thanks.nextSteps.text'>
              <strong />
            </Trans>
          </MedTypography>
          <Link href='/signup'>
            <MedButton fullWidth>{t('pages.Thanks.nextSteps.cta')}</MedButton>
          </Link>
        </div>
      ) : (
        <>
          {isLoadingEffects && (
            <div className={classes.chartWrapper}>
              <CircularProgress className={classes.progress} />
            </div>
          )}
          {!isLoadingEffects && (
            <>
              <div className={classes.paragraphWrapper}>
                <MedTypography variant='body1'>
                  {t('pages.Thanks.thankYouParagraph')}
                </MedTypography>
                {showResultsButton && (
                  <MedButton variant='text' fullWidth onClick={handleNext}>
                    {t('pages.Thanks.showResults')}
                    <NavigateNextIcon />
                  </MedButton>
                )}
              </div>
              <ConditionalSuccessButton />
            </>
          )}
        </>
      )
    return (
      <>
        <div className={classes.contentWrapper}>
          <MedTypography variant='h2' align='center'>
            {t('pages.Thanks.thankYou')}
          </MedTypography>
          {content}
        </div>
        {find(values.drugs, {
          atc_international: config.COVID_VACCINES.vaxzevria
        }) && (
          <div className={classes.vaccineWarning}>
            <div className={classes.vaccineWarningTitle}>
              <MedTypography
                variant='h3'
                className={classes.vaccineWarningText}
              >
                <VaccineAlertImage />
                {t('pages.Thanks.vaccineWarning.astrazeneca.title')}
              </MedTypography>
            </div>
            <MedTypography
              variant='body1'
              className={classes.vaccineWarningText}
            >
              {t('pages.Thanks.vaccineWarning.astrazeneca.description')}
            </MedTypography>
          </div>
        )}
        {find(values.drugs, {
          atc_international: config.COVID_VACCINES.johnson
        }) && (
          <div className={classes.vaccineWarning}>
            <div className={classes.vaccineWarningTitle}>
              <MedTypography
                variant='h3'
                className={classes.vaccineWarningText}
              >
                <VaccineAlertImage />
                {t('pages.Thanks.vaccineWarning.johnsonjohnson.title')}
              </MedTypography>
            </div>
            <MedTypography
              variant='body1'
              className={classes.vaccineWarningText}
            >
              {t('pages.Thanks.vaccineWarning.johnsonjohnson.description')}
            </MedTypography>
          </div>
        )}

        {appMode !== 'medWatcher' && sourceDomain !== 'data4life.care' && (
          <BannerMedWatcherLarge />
        )}
      </>
    )
  } else {
    return (
      <>
        <ReportFailedIcon className={classes.reportFailedIcon} />
        <MedTypography variant='h2' align='center'>
          {t('pages.Thanks.somethingFailed')}
        </MedTypography>
        <MedTypography variant='body1'>
          {t('pages.Thanks.failureText1')}
        </MedTypography>
        {status === REPORT_ERROR_NOCONNECTION ? (
          <>
            <MedTypography variant='body1'>
              {t('pages.Thanks.failureText2.connectionFail')}
            </MedTypography>
            <NextButton
              label={t('pages.Thanks.retry')}
              disabled={isSubmitting}
            />
          </>
        ) : (
          <MedTypography variant='body1'>
            {t('pages.Thanks.failureText2.hardFail')}
          </MedTypography>
        )}
      </>
    )
  }
}
export default withTranslation()(Thanks)
