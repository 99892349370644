import { array, object, string, mixed } from 'yup'
import { dateShape } from 'utils/validation'

const SideEffectsSchema = {
  effects: array().of(
    object().shape({
      name: string().required(),

      startDate: dateShape.isFuture('dateShouldNotBeInFuture'),

      endDate: object().when('ongoing', {
        is: true,
        then: object()
          .nullable()
          .notRequired(),
        otherwise: dateShape
          .isBefore('endDateShouldBeAfterStartDate')
          .isFuture('dateShouldNotBeInFuture')
      })
    })
  )
}

export default SideEffectsSchema
