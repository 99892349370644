import { object, string } from 'yup'
import { phoneShape } from 'utils/validation'

const EmailSchema = {
  identity: object().shape({
    email: string().email(),
    phone: phoneShape.phoneTest()
  })
}

export default EmailSchema
