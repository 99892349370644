import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { getStringParamFromRootData } from 'utils/params'
import { withTranslation } from 'react-i18next'

import { ReactComponent as SideEffectsIcon } from 'assets/side-effects.svg'
import { ReactComponent as DrugsIcon } from 'assets/drugs.svg'
import { ReactComponent as PatientIcon } from 'assets/patient.svg'
import { ReactComponent as ContactIcon } from 'assets/contact.svg'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '0.6rem'
  },
  defaultRoot: {
    paddingTop: '0.6rem',
    marginTop: theme.spacing(1)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  icon: {
    background: '#FFF',
    border: '1px solid',
    borderColor: theme.palette.text.secondary,
    borderRadius: '50%',
    boxShadow: 'none',
    paddingTop: '2px',
    width: '30px',
    height: '30px',
    textAlign: 'center',
    zIndex: 1,

    '& svg': {
      width: '24px',
      height: '24px',

      '& path': {
        fill: theme.palette.text.secondary
      }
    }
  },
  iconActive: {
    borderColor: theme.palette.primary.main,
    background: theme.palette.primary.main,

    '& svg': {
      '& path': {
        fill: theme.palette.primary.contrastText
      }
    }
  },
  line: {
    position: 'relative',
    width: 'calc(((100% - 120px) / 4) + 6px)',
    boxShadow: 'none',
    height: '7px',
    margin: '0 -3px',

    '& div': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      borderRadius: '0 3px 3px 0',
      width: '0%',
      background: theme.palette.primary.main,
      transition: 'all 1s'
    },

    '&:before': {
      display: 'block',
      content: `""`,

      position: 'absolute',
      top: '3px',
      left: 0,
      right: 0,
      height: '1px',
      background: theme.palette.text.secondary
    }
  },
  textRow: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  text: {
    width: '25%',
    marginTop: theme.spacing(0.5),
    textAlign: 'center'
  },
  textActive: {
    color: theme.palette.primary.main
  }
}))

const ProgressBar = ({ currentPageIndex, pages, t }) => {
  const classes = useStyles()

  const appMode = getStringParamFromRootData('mode', 'default')
  const currentPageCategory = pages[currentPageIndex].category

  // :TODO: We don't have to recreate the tree every time we render the progressbar only update it.

  const pageCategories = []
  let currentPageCategoryBefore = 0
  let currentPageCategoryAfter = 0
  for (let i = 0; i < pages.length; i++) {
    if (
      pages[i].category !== '' &&
      pages[i].category !== 'complete' &&
      pages[i].category !== 'result' &&
      !pageCategories.includes(pages[i].category)
    ) {
      pageCategories.push(pages[i].category)
    }

    if (
      pages[i].category === currentPageCategory &&
      pages[i].noProgress === false
    ) {
      if (i <= currentPageIndex) {
        currentPageCategoryBefore++
      } else {
        currentPageCategoryAfter++
      }
    }
  }

  const iconChildren = []
  const textChildren = []
  let beforeCurrent = true
  for (let i = 0; i < pageCategories.length; i++) {
    let icon = null
    switch (pageCategories[i]) {
      case 'sideEffects':
        icon = <SideEffectsIcon />
        break
      case 'drugs':
        icon = <DrugsIcon />
        break
      case 'patient':
        icon = <PatientIcon />
        break
      case 'contact':
        icon = <ContactIcon />
        break
      default:
        continue
    }

    iconChildren.push(
      <div
        key={'icon' + i}
        className={clsx(classes.icon, beforeCurrent ? classes.iconActive : '')}
      >
        {icon}
      </div>
    )

    textChildren.push(
      <div
        key={'text' + i}
        className={clsx(classes.text, beforeCurrent ? classes.textActive : '')}
      >
        {t('progressBar.' + pageCategories[i])}
      </div>
    )

    let percentage = 0
    if (pageCategories[i] === currentPageCategory) {
      percentage = Math.round(
        ((currentPageCategoryBefore - 1) /
          (currentPageCategoryBefore + currentPageCategoryAfter)) *
          100
      )
      beforeCurrent = false
    } else if (beforeCurrent) {
      percentage = 100
    }
    if (i < pageCategories.length - 1) {
      iconChildren.push(
        <div key={'line' + i} className={classes.line}>
          <div style={{ width: percentage + '%' }} />
        </div>
      )
    }
  }

  return (
    <div
      className={clsx(
        classes.root,
        appMode === 'default' ? classes.defaultRoot : ''
      )}
    >
      <div className={classes.row}>{iconChildren}</div>
      <div className={clsx(classes.row, classes.textRow)}>{textChildren}</div>
    </div>
  )
}

ProgressBar.propTypes = {
  currentPageIndex: PropTypes.number.isRequired,
  pages: PropTypes.array.isRequired
}

export default withTranslation()(ProgressBar)
