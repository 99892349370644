import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { cloneDeep, get, has, isEmpty, range, unset } from 'lodash'
import moment from 'moment'
import { convertToDate, getLocalizedEnumeration } from 'utils/helper'

import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'
import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import DateInput from 'components/DateInput'
import NextButton from 'components/NextButton'

import { trackEvent } from 'service/tracker'
import config from 'config'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  },
  doseWrapper: {
    padding: theme.spacing(0, 0, 3),
    '& > .MuiFormControl-root': {
      padding: theme.spacing(0, 0, 1)
    },
    '& > .MuiGrid-container': {
      paddingTop: theme.spacing(1)
    }
  }
}))

const CovidVaccines = ({
  values,
  errors,
  setCovidVaccines,
  setFieldValue,
  handleChange: formikHandleChange,
  handleBlur: formikHandleBlur,
  goNextPage,
  t
}) => {
  const classes = useStyles()
  const isDisabled =
    get(values, 'temp.vaccines.covid').filter((vaccine, index) => {
      const type = get(vaccine, 'type', '')
      if (type === '' || type === 'none') {
        return false
      }

      return (
        !isEmpty(get(errors, `temp.vaccines.covid[${index}]`, {})) ||
        get(vaccine, 'date.mm', '') === '' ||
        get(vaccine, 'date.yyyy', '') === ''
      )
    }).length > 0

  useEffect(() => {
    trackEvent('PageView - Covid Vaccines')
  }, [])

  useEffect(() => {
    // Resetting the values below the value you just deselected
    const vaccines = cloneDeep(get(values, 'temp.vaccines.covid'))
    let valuesChanged = false
    for (let index = 0; index < vaccines.length; index++) {
      const selectedType = get(vaccines, `[${index}].type`, '')
      let clearFollowing = false
      if (selectedType === '') {
        vaccines.splice(index)
        valuesChanged = true
        break
      } else if (selectedType === 'none') {
        clearFollowing = true
      }
      if (selectedType === 'other') {
        if (get(vaccines, `[${index}].custom`, '') === '') {
          clearFollowing = true
        } else if (clearFollowing) {
          unset(vaccines, `[${index}].custom`)
          valuesChanged = true
        }
      } else if (get(vaccines, `[${index}].custom`, '') !== '') {
        unset(vaccines, `[${index}].custom`)
        valuesChanged = true
      }
      if (get(vaccines, `[${index}].dose`, '') === '') {
        clearFollowing = true
      } else if (clearFollowing) {
        unset(vaccines, `[${index}].dose`)
        valuesChanged = true
      }
      if (
        get(vaccines, `[${index}].date.mm`, '') === '' ||
        get(vaccines, `[${index}].date.yyyy`, '') === ''
      ) {
        clearFollowing = true
      } else if (clearFollowing) {
        unset(vaccines, `[${index}].date`)
        valuesChanged = true
      }
      if (clearFollowing && vaccines.length > index + 1) {
        vaccines.splice(index + 1)
        valuesChanged = true
        break
      }
    }
    if (valuesChanged) {
      setFieldValue('temp.vaccines.covid', vaccines)
    }
  }, [values])

  const handleNext = event => {
    event.preventDefault()
    setCovidVaccines(values.temp.vaccines.covid)
    goNextPage()
  }

  let firstEffectStart = null
  let lastEffectStart = null
  for (const effect of values.effects) {
    const effectStart = moment(convertToDate(effect.startDate))
    if (firstEffectStart === null || effectStart < firstEffectStart) {
      firstEffectStart = moment(effectStart)
    }
    if (lastEffectStart === null || effectStart > lastEffectStart) {
      lastEffectStart = moment(effectStart)
    }
  }

  const usedDoses = get(values, 'temp.vaccines.covid')
    .filter(vaccine => vaccine.dose !== undefined)
    .map(vaccine => vaccine.dose)

  const vaccineChildren = []
  for (let index = 0; index < config.COVID_VACCINE_DOSES; index++) {
    if (
      index === 0 ||
      (get(values, `temp.vaccines.covid[${index - 1}].date.mm`, '') !== '' &&
        get(values, `temp.vaccines.covid[${index - 1}].date.yyyy`, ''))
    ) {
      const selectedType = get(values, `temp.vaccines.covid[${index}].type`, '')
      const selectedDose = get(values, `temp.vaccines.covid[${index}].dose`, '')
      const selectedCustom = get(
        values,
        `temp.vaccines.covid[${index}].custom`,
        ''
      )
      const doseChildren = []
      doseChildren.push(
        <MedDropDown
          key={'type' + index}
          className={classes.dropDown}
          name={`temp.vaccines.covid[${index}].type`}
          label={t('pages.CovidVaccines.vaccine')}
          placeholder={t('pages.CovidVaccines.placeholder')}
          autoNativeOnMobile
          onChange={formikHandleChange}
          onBlur={formikHandleBlur}
          value={get(values, `temp.vaccines.covid[${index}].type`, '')}
          textKey='label'
          valueKey='value'
          dataList={[
            ...[...Object.keys(config.COVID_VACCINES), 'other'].map(
              vaccineKey => ({
                label: t('common.vaccines.covid.' + vaccineKey),
                value: vaccineKey
              })
            ),
            {
              label: t(
                index === 0
                  ? 'pages.CovidVaccines.noVaccine'
                  : 'pages.CovidVaccines.noFurtherVaccine'
              ),
              value: 'none'
            }
          ]}
        />
      )
      if (selectedType === 'other') {
        doseChildren.push(
          <MedTextField
            key={'custom' + index}
            fullWidth
            autoFocus={selectedCustom === ''}
            name={`temp.vaccines.covid[${index}].custom`}
            label={t('pages.CovidVaccines.vaccineCustom')}
            value={get(values, `temp.vaccines.covid[${index}].custom`, '')}
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
          />
        )
      }
      if (
        selectedType === 'other'
          ? selectedCustom !== ''
          : selectedType !== '' && selectedType !== 'none'
      ) {
        doseChildren.push(
          <MedDropDown
            key={'dose' + index}
            className={classes.dropDown}
            name={`temp.vaccines.covid[${index}].dose`}
            label={t('pages.CovidVaccines.dose')}
            placeholder={t('pages.CovidVaccines.placeholder')}
            autoNativeOnMobile
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
            value={get(values, `temp.vaccines.covid[${index}].dose`, '')}
            textKey='label'
            valueKey='value'
            dataList={range(1, config.COVID_VACCINE_DOSES + 1)
              .filter(
                dose => dose === selectedDose || !usedDoses.includes(dose)
              )
              .map(value => ({
                label:
                  getLocalizedEnumeration(value) +
                  ' ' +
                  t('pages.CovidVaccines.doseEnd'),
                value
              }))}
            error={has(errors, `temp.vaccines.covid[${index}].dose`)}
            helperText={
              has(errors, `temp.vaccines.covid[${index}].dose`)
                ? t('pages.CovidVaccines.invalidDoseTimeline')
                : undefined
            }
          />
        )
      }
      if (selectedDose !== '') {
        doseChildren.push(
          <DateInput
            key={'date' + index}
            name={`temp.vaccines.covid[${index}].date`}
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
            autoFocus
            value={get(values, `temp.vaccines.covid[${index}].date`, {
              dd: '',
              mm: '',
              yyyy: ''
            })}
            minYear={2020}
            error={
              get(values, `temp.vaccines.covid[${index}].date.mm`, '') !== '' &&
              get(values, `temp.vaccines.covid[${index}].date.yyyy`, '') !==
                '' &&
              get(errors, `temp.vaccines.covid[${index}].date`)
            }
          />
        )
      }
      vaccineChildren.push(
        <div
          key={'children' + vaccineChildren.length}
          className={classes.doseWrapper}
        >
          {doseChildren}
        </div>
      )
    }
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.CovidVaccines.heading'>
            <strong />
            {{
              start: firstEffectStart
                .subtract(config.COVID_VACCINE_SIDEEFFECT_MONTH, 'months')
                .format(t('pages.CovidVaccines.format'))
            }}
            {{
              end: lastEffectStart.format(t('pages.CovidVaccines.format'))
            }}
          </Trans>
        </MedTypography>
        {vaccineChildren}
      </div>
      <NextButton disabled={isDisabled} onClick={handleNext} />
    </>
  )
}

export default withTranslation()(CovidVaccines)
