import React, { useState, useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { has, get } from 'lodash'
import { hyphenatedText } from 'utils/helper'

import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import DateInput from 'components/DateInput'
import NextButton from 'components/NextButton'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  }
}))

const CovidVaccineInfo = ({
  values,
  errors,
  touched,
  itemIndex: index,
  setFieldValue,
  handleChange: formikHandleChange,
  handleBlur: formikHandleBlur,
  goNextPage,
  t
}) => {
  const classes = useStyles()

  const [displayError, setDisplayError] = useState(false)

  const isError = has(errors, `drugs[${index}].endDate`)

  const isTouched = has(touched, `drugs[${index}].endDate`)

  const drugName = hyphenatedText(values.drugs[index].medicine)

  const isDisabled = !(
    get(values, `drugs[${index}].endDate.mm`) &&
    get(values, `drugs[${index}].endDate.yyyy`) &&
    get(values, `drugs[${index}].vaccinedose`)
  )

  useEffect(() => {
    trackEvent('PageView - Covid Vaccine Info ' + (index + 1))
    if (!has(values, `drugs[${index}].endDate`)) {
      setFieldValue(`drugs[${index}].endDate`, {
        dd: '',
        mm: '',
        yyyy: new Date().getFullYear()
      })
    }
  }, [])

  const checkAndProceed = () => {
    if (isError) {
      setDisplayError(true)
      trackEvent('ErrorWrongDate - Covid Vaccine Info ' + (index + 1))
    } else {
      trackEvent('Submit - Covid Vaccine Info ' + (index + 1))
      setFieldValue(
        `drugs[${index}].startDate`,
        get(values, `drugs[${index}].endDate`)
      )
      goNextPage()
    }
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.CovidVaccineInfo.heading'>
            <strong>{{ drugName }}</strong>
          </Trans>
        </MedTypography>

        <DateInput
          name={`drugs[${index}].endDate`}
          onChange={formikHandleChange}
          onBlur={formikHandleBlur}
          autoFocus
          minYear={2020}
          value={
            values.drugs[index].endDate || {
              dd: '',
              mm: '',
              yyyy: ''
            }
          }
          error={
            displayError &&
            isTouched &&
            isError &&
            get(errors, `drugs[${index}].endDate`)
          }
        />

        {get(values, `drugs[${index}].endDate.mm`) &&
          get(values, `drugs[${index}].endDate.yyyy`) && (
            <MedDropDown
              className={classes.unitPadding}
              name={`drugs[${index}].vaccinedose`}
              label={t('pages.CovidVaccineInfo.whichOne')}
              placeholder={t('pages.CovidVaccineInfo.placeholder')}
              autoNativeOnMobile
              onChange={formikHandleChange}
              onBlur={formikHandleBlur}
              value={get(values, `drugs[${index}].vaccinedose`, '')}
              textKey='label'
              valueKey='value'
              dataList={[
                {
                  label: t('pages.CovidVaccineInfo.first'),
                  value: 'first'
                },
                {
                  label: t('pages.CovidVaccineInfo.second'),
                  value: 'second'
                },
                {
                  label: t('pages.CovidVaccineInfo.third'),
                  value: 'third'
                },
                {
                  label: t('pages.CovidVaccineInfo.dontKnow'),
                  value: 'dontKnow'
                }
              ]}
            />
          )}
      </div>
      <NextButton disabled={isDisabled} onClick={checkAndProceed} />
    </>
  )
}

export default withTranslation()(CovidVaccineInfo)
