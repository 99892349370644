import React, { useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import 'i18n'
import * as Sentry from '@sentry/browser'
import {
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core/styles'
import { Buffer } from 'buffer'
import MedThemeWrapper from '@bit/medikura.med-lib-uno.med-theme-wrapper'
import { withTranslation } from 'react-i18next'

import { getRootElement, getStringParamFromRootData } from 'utils/params'

import ErrorBoundary from 'components/ErrorBoundary'
import WizardWrapper from 'components/WizardWrapper'
import ReportWizard from './../ReportWizard'
import Matomo from 'service/Matomo'

import reportTemplate from '../ReportWizard/reportTemplate'
import config from 'config'
import { get } from 'lodash'

const generateClassName = createGenerateClassName({
  productionPrefix: 'medikuraCapture'
})

const getCustomPalette = () => {
  const paletteParam = getStringParamFromRootData('palette')
  if (!paletteParam) {
    return {}
  }

  const paletteText = Buffer.from(paletteParam, 'base64').toString('ascii')
  try {
    return JSON.parse(paletteText)
  } catch (e) {
    console.error('Invalid palette parameter', paletteText)
    return {}
  }
}

function App (props) {
  const [contentLoaded, setContentLoaded] = React.useState(false)

  useEffect(() => {
    const patientToken = getStringParamFromRootData('patientToken')
    if (!patientToken) {
      setContentLoaded(true)
      return
    }
    axios
      .get(`${config.IDENTITY_BASE_URL}/patient/me`, {
        headers: {
          'Content-Type': 'application/json',
          'X-API-Version': '1.0.0',
          Authorization: 'Bearer ' + patientToken
        }
      })
      .then(function (response) {
        if (response.data.covidData) {
          for (const [index, vaccineFieldName] of [
            'firstVaccine',
            'secondVaccine',
            'thirdVaccine'
          ].entries()) {
            if (
              response.data.covidData[vaccineFieldName] &&
              response.data.covidData[vaccineFieldName + 'Date']
            ) {
              const vaccine = response.data.covidData[vaccineFieldName]
              const vaccinationDate = moment(
                response.data.covidData[vaccineFieldName + 'Date']
              )
              if (moment().diff(vaccinationDate) < 0) {
                continue
              }
              reportTemplate.temp.vaccines.covid.push({
                type: vaccine,
                ...(vaccine === 'other' &&
                  response.data.covidData[vaccineFieldName + 'FreeText'] && {
                    custom:
                      response.data.covidData[vaccineFieldName + 'FreeText']
                  }),
                dose: index + 1,
                date: {
                  dd: vaccinationDate.date(),
                  mm: vaccinationDate.month() + 1,
                  yyyy: vaccinationDate.year()
                }
              })
            }
          }
        }
        if (response.data.medicines) {
          for (const medicine of response.data.medicines.filter(
            medicine => medicine.isNotTakingThisMedicine !== true
          )) {
            const obj = {
              medicine: medicine.name,
              pzn: get(medicine, 'codes.pzn'),
              atc_international: get(medicine, 'codes.atc'),
              anbieter_id: get(medicine, 'manufacturer.ifapAnbieterId'),
              firmenname: get(medicine, 'manufacturer.name')
            }

            const whenStarted = get(medicine, 'whenStarted.selectedChoice')
            if (whenStarted === 'exactDate') {
              const dateObj = new Date(
                get(medicine, 'whenStarted.calculatedDate')
              )
              obj.startDate = {
                dd: dateObj.getDate(),
                mm: dateObj.getMonth() + 1,
                yyyy: dateObj.getFullYear()
              }
              obj.startDateRough = 'exactDate'
            } else {
              const dateObj = new Date(
                get(medicine, 'whenStarted.calculatedDate')
              )
              if (!isNaN(dateObj.getTime())) {
                obj.startDate = {
                  dd: '',
                  mm: dateObj.getMonth() + 1,
                  yyyy: dateObj.getFullYear()
                }
                obj.startDateRough = 'exactDate'
              }
            }
            const whenStopped = get(medicine, 'whenStopped.selectedChoice')
            if (whenStopped === 'stillTaking') {
              obj.endDate = {
                dd: '',
                mm: '',
                yyyy: ''
              }
              obj.ongoing = true
            } else if (whenStopped === 'exactDate') {
              const dateObj = new Date(
                get(medicine, 'whenStopped.calculatedDate')
              )
              obj.endDate = {
                dd: dateObj.getDate(),
                mm: dateObj.getMonth() + 1,
                yyyy: dateObj.getFullYear()
              }
              obj.endDateRough = 'exactDate'
              obj.ongoing = false
            } else {
              const dateObj = new Date(
                get(medicine, 'whenStopped.calculatedDate')
              )
              if (!isNaN(dateObj.getTime())) {
                obj.endDate = {
                  dd: '',
                  mm: dateObj.getMonth() + 1,
                  yyyy: dateObj.getFullYear()
                }
                obj.endDateRough = 'exactDate'
                obj.ongoing = false
              }
            }
            reportTemplate.drugs.push(obj)
          }
        }

        if (response.data.gender) {
          switch (response.data.gender) {
            case 'male':
            case 'female':
              reportTemplate.contacts.patient.gender = response.data.gender
              break
            case 'diverse':
              reportTemplate.contacts.patient.gender = 'diverse'
              break
            default:
              break
          }
        }
        if (response.data.dateOfBirthYear) {
          reportTemplate.contacts.patient.dateOfBirthYear =
            response.data.dateOfBirthYear
        }
        if (response.data.height) {
          reportTemplate.contacts.patient.height = response.data.height
        }
        if (response.data.weight) {
          reportTemplate.contacts.patient.weight = response.data.weight
        }
        reportTemplate.contacts.reporter.identity.email = response.data.email
      })
      .catch(function (error) {
        Sentry.captureException(error)
      })
      .then(function () {
        setContentLoaded(true)
      })
  }, [])

  useEffect(() => {
    // check for changes in its attributes, i.e. changes of the language
    const rootElement = getRootElement()
    const rootElementObserver = new MutationObserver(() => {
      const language = getStringParamFromRootData('lang', '')

      if (language && props.i18n.language !== language) {
        props.i18n.changeLanguage(language)
      }
    })

    rootElementObserver.observe(rootElement, {
      childList: false,
      subtree: false,
      attributes: true
    })

    return () => {
      rootElementObserver.disconnect()
    }
  }, [])

  if (!contentLoaded) {
    return null
  }

  return (
    <>
      <ErrorBoundary>
        <StylesProvider generateClassName={generateClassName}>
          <MedThemeWrapper theme='capture' palette={getCustomPalette()}>
            <WizardWrapper>
              <ReportWizard reportTemplate={reportTemplate} />
            </WizardWrapper>
          </MedThemeWrapper>
          <Matomo />
        </StylesProvider>
      </ErrorBoundary>
    </>
  )
}

export default withTranslation()(App)
