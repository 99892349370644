import * as Sentry from '@sentry/browser'

const handleOnSearch = (
  searchFunction,
  searchText,
  labelKey,
  defaultResult,
  setList
) => {
  var validQuery = /[\w\d]/
  if (validQuery.test(searchText)) {
    searchFunction(searchText)
      .then(response => {
        if (
          searchText.trim() &&
          (response.length === 0 ||
            searchText.localeCompare(response[0][labelKey], undefined, {
              sensitivity: 'accent'
            }) !== 0)
        ) {
          response.unshift(defaultResult)
        }
        setList(response)
        // setNetworkOrApiError(false)
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
        // setNetworkOrApiError(true)
      })
  } else {
    setList([])
  }
}

const handleOnSelect = (value, item, setList, props, propFieldName) => {
  // Reset the search list on selecting an entry
  setList([])
  if (item) {
    props.setFieldValue(propFieldName, item)
    if (item.length > 0) {
      const lastEntry = item.length - 1
      if (typeof item[lastEntry] === 'string') {
        item[lastEntry] = item[lastEntry].trim()
        if (item[lastEntry] === '') {
          item.splice(lastEntry, 1)
        }
      }
    }
  } else {
    const localCopy = Object.assign([], props.values[propFieldName])
    value = value.trim()
    if (!localCopy.includes(value) && value !== '') {
      localCopy.push(value)
      props.setFieldValue(propFieldName, localCopy)
    }
  }
}

export { handleOnSearch, handleOnSelect }
