import React from 'react'
import { Formik } from 'formik'
import Cookies from 'js-cookie'
import { set } from 'lodash'
import * as Sentry from '@sentry/browser'

import { getStringParamFromURL } from 'utils/params'

import AppHeader from 'containers/AppHeader'
import UnloadPrevention from 'components/UnloadPrevention'

import { trackEvent } from 'service/tracker'
import { getApp } from 'utils/helper'

class Wizard extends React.Component {
  static Page = ({ children, parentState }) => {
    return <>{React.cloneElement(children, { ...parentState })}</>
  }

  constructor (props) {
    super(props)

    let abCookie = getStringParamFromURL(
      window.location.search,
      'ab',
      Cookies.get('capture-ab')
    )
    if (abCookie !== 'a' && abCookie !== 'b') {
      abCookie = Math.random() < 0.5 ? 'a' : 'b'
      Cookies.set('capture-ab', abCookie)
    }

    if (abCookie === 'a') {
      trackEvent('Widget A')
    } else if (abCookie === 'b') {
      trackEvent('Widget B')
    }

    const app = getApp()

    this.state = {
      history: [''],
      page: 0,
      ab: abCookie,
      app,
      values: props.initialValues,
      childPages: props.children,
      progressPages: this.getProgressPages(abCookie, app, props.children)
    }
    window.onpopstate = e => this.handleBrowserNavigation(e)

    if (window.location.hash !== '') {
      // Automatic jumping back till start when user is reloading the page
      window.history.back()
    }

    trackEvent('Open Widget')
  }

  handleBrowserNavigation = e => {
    const { history } = this.state

    if (
      history.length > 0 &&
      history[history.length - 1].startsWith('#complete-')
    ) {
      // Reload page if going back on results page
      window.location.reload()
    }

    if (
      history.length > 1 &&
      history[history.length - 2] === window.location.hash
    ) {
      this.handlePrevious()
    } else if (
      history.length > 0 &&
      history[history.length - 1] === window.location.hash
    ) {
      // This case happens when the user tries to go forward
      // and is returned in the else-block below
    } else {
      // Prevents users mocking around in the jumpmarks or
      // going forward again
      window.history.back()
    }
  }

  // :TODO: check if the values could be cached instead
  // of running this function over and over again
  filterChildren = (ab, app, children) => {
    const flatChildren = React.Children.toArray(children)
    return flatChildren.filter(child => {
      return (
        (!child.props.filterAb || child.props.filterAb === ab) &&
        (!child.props.filterApp || child.props.filterApp === app)
      )
    })
  }

  handleNext = () => {
    const children = this.filterChildren(
      this.state.ab,
      this.state.app,
      this.state.childPages
    )
    const page = Math.min(this.state.page + 1, children.length - 1)

    const category = children[page].props.category
    let categoryCount = 1
    for (let i = 0; i < page; i++) {
      if (category === children[i].props.category) {
        categoryCount++
      }
    }
    window.history.pushState({}, '', '#' + category + '-' + categoryCount)

    this.setState({
      page,
      history: [...this.state.history, '#' + category + '-' + categoryCount]
    })
  }

  handlePrevious = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
      history: state.history.slice(0, state.history.length - 1)
    }))

  validate = async values => {
    const activePage = this.filterChildren(
      this.state.ab,
      this.state.app,
      this.props.children
    )[this.state.page]

    try {
      if (activePage.props.validate) {
        await this.props.validationSchema.validateAt(
          activePage.props.validate,
          values,
          { context: values }
        )
      }
      return {}
    } catch (error) {
      if (typeof error === 'object' && error.name === 'ValidationError') {
        return set({}, error.path, error.message)
      } else {
        console.error(error)
        Sentry.captureException(error)
        return {}
      }
    }
  }

  handleSubmit = (values, bag) => {
    const { onSubmit } = this.props
    return onSubmit(values, bag)
  }

  componentDidUpdate (prevProps) {
    if (this.props.children !== prevProps.children) {
      this.setState({
        childPages: this.props.children,
        progressPages: this.getProgressPages(
          this.state.ab,
          this.state.app,
          this.props.children
        )
      })
    }
  }

  getProgressPages = (ab, app, childPages) => {
    const children = this.filterChildren(ab, app, childPages)
    const progressPages = []
    for (const child of children) {
      let page = {
        category: '',
        noProgress: true
      }
      if (child.props !== undefined && child.props.category !== undefined) {
        page = {
          category: child.props.category,
          noProgress: child.props.noProgress || false
        }
      }
      if (child.props.children !== undefined) {
        page.name =
          child.props.children.type.displayName ||
          child.props.children.type.name
      }
      progressPages.push(page)
    }
    return progressPages
  }

  render () {
    const { page, ab, app, values, childPages, progressPages } = this.state
    const activePage = this.filterChildren(ab, app, childPages)[page]
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {formik => (
          <>
            <UnloadPrevention
              formik={formik}
              currentPageIndex={page}
              pages={progressPages}
            />
            <form onSubmit={formik.handleSubmit}>
              {progressPages[page].category !== '' && (
                <AppHeader
                  currentPageIndex={page}
                  pages={progressPages}
                  onPreviousPageClick={e => {
                    window.history.back()
                  }}
                />
              )}
              {React.cloneElement(activePage, {
                parentState: {
                  ...formik,
                  ab,
                  validate: () => {
                    this.validate(formik.values)
                  },
                  goNextPage: this.handleNext
                }
              })}
            </form>
          </>
        )}
      </Formik>
    )
  }
}

export default Wizard
