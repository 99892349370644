import React, { useEffect } from 'react'

import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import NextButton from 'components/NextButton'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  }
}))

const HabitComments = ({
  item,
  handleChange,
  handleBlur,
  values,
  goNextPage,
  t
}) => {
  const classes = useStyles()
  useEffect(() => trackEvent('PageView - HabitComment ' + item), [])

  const setAndProceed = event => {
    event.preventDefault()
    trackEvent('Submit - HabitComment ' + item)
    goNextPage()
  }

  // Currently the value might already be a localized value. So use the string itself as backup.
  const itemName = t('pages.Habits.options.' + item, item)
  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.HabitComments.heading'>
            <strong>{{ itemName }}</strong>
          </Trans>{' '}
          ({t('common.optional')})
        </MedTypography>
        <MedTextField
          name={`habitComments.${item}`}
          placeholder={t('pages.HabitComments.textfield')}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          value={values.habitComments[item] || ''}
          multiline
        />
      </div>
      <NextButton onClick={setAndProceed} />
    </>
  )
}
export default withTranslation()(HabitComments)
