import React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import I18n from 'i18n'
import * as Sentry from '@sentry/browser'
import { makeStyles } from '@material-ui/core/styles'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'
import MedBarChart from '@bit/medikura.med-lib-uno.med-bar-chart'
import CircularProgress from '@material-ui/core/CircularProgress'

import BannerMedWatcherSmall from 'components/BannerMedWatcherSmall'
import ConditionalSuccessButton from 'components/ConditionalSuccessButton'

import { getStringParamFromRootData } from 'utils/params'
import { hyphenatedText } from 'utils/helper'
import { getEffects } from 'service/statistics'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    padding: theme.spacing(2, 0, 1),
    display: 'flex',
    flexDirection: 'column'
  },
  dropDownWrapper: {
    width: '50%',
    minWidth: '240px',
    margin: '0 auto'
  },
  chartWrapper: {
    background: '#FFFCFA',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    margin: theme.spacing(1, 1, 2)
  },
  progress: {
    display: 'block',
    margin: theme.spacing(0, 'auto'),
    color: theme.palette.secondary.main
  }
}))

const Result = props => {
  const classes = useStyles()
  const appMode = getStringParamFromRootData('mode', 'default')

  const [drugIndex, setDrugIndex] = React.useState(1)
  const [hasAtcCode, setHasAtcCode] = React.useState(false)
  const [drugList, setDrugList] = React.useState([])

  const [drugName, setDrugName] = React.useState('')
  const [commonSideEffects, setCommonSideEffects] = React.useState({})
  const [hasCommonSideEffects, setHasCommonSideEffects] = React.useState(false)
  const [newSideEffects, setNewSideEffects] = React.useState([])

  const [isLoadingEffects, setLoadingEffects] = React.useState(true)

  React.useEffect(() => {
    setDrugList(
      props.values.drugs.map((drug, index) => ({
        index: index + 1,
        medicine: drug.medicine,
        atc_international: drug.atc_international
      }))
    )
  }, [])

  React.useEffect(() => {
    if (drugList.length === 0) {
      return
    }

    setLoadingEffects(true)
    const drug = drugList[drugIndex - 1]
    setDrugName(hyphenatedText(drug.medicine))
    setHasCommonSideEffects(false)
    if (drug.atc_international) {
      setHasAtcCode(true)
      getEffects(drug.atc_international, I18n.language)
        .then(response => {
          setLoadingEffects(false)
          const newCommonSideEffects = {}
          const newNewSideEffects = []
          for (const reportedSideEffect of props.values.effects) {
            let sideEffectContained = false
            for (const sideEffect of response.sideEffects) {
              const ptCodes = sideEffect.ptCodes
                ? sideEffect.ptCodes
                : [sideEffect.ptCode]

              if (ptCodes.includes(reportedSideEffect.pt_code)) {
                newCommonSideEffects[reportedSideEffect.name] =
                  sideEffect.percentage
                setHasCommonSideEffects(true)
                sideEffectContained = true
              }
            }
            if (!sideEffectContained) {
              newNewSideEffects.push(reportedSideEffect.name)
            }
          }
          setCommonSideEffects(newCommonSideEffects)
          setNewSideEffects(newNewSideEffects)
        })
        .catch(error => {
          // :TODO: error handling
          setLoadingEffects(false)
          console.error(error)
          Sentry.captureException(error)
        })
    } else {
      setLoadingEffects(false)
      setHasAtcCode(false)
    }
  }, [drugList, drugIndex])

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2' align='center' color='primary'>
          <Trans i18nKey='pages.Result.yourResult'>
            <strong>{{ drugName }}</strong>
          </Trans>
        </MedTypography>
        {drugList.length > 1 && (
          <div className={classes.dropDownWrapper}>
            <MedDropDown
              variant='outlined'
              dataList={drugList}
              textKey='medicine'
              valueKey='index'
              value={drugIndex}
              onChange={event => {
                setDrugIndex(event.target.value)
              }}
            />
          </div>
        )}
        {isLoadingEffects && (
          <div className={classes.chartWrapper}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
        {!isLoadingEffects && hasAtcCode && hasCommonSideEffects && (
          <div className={classes.chartWrapper}>
            <MedTypography variant='h3'>
              <Trans i18nKey='pages.Result.bar.heading'>
                <strong>{{ drugName }}</strong>
              </Trans>
            </MedTypography>
            <MedBarChart
              color='#D95384'
              data={commonSideEffects}
              limit={100}
              postfix='%'
            />
          </div>
        )}
        {!isLoadingEffects &&
          hasAtcCode &&
          newSideEffects.map((sideEffect, index) => (
            <div key={index} className={classes.chartWrapper}>
              <MedTypography variant='h3'>
                <Trans i18nKey='pages.Result.bar.notEnoughReports'>
                  <strong>{{ sideEffect }}</strong>
                  <strong>{{ drugName }}</strong>
                </Trans>
              </MedTypography>
            </div>
          ))}
        {!isLoadingEffects &&
          (!hasAtcCode ||
            (!hasCommonSideEffects && newSideEffects.length === 0)) && (
            <div className={classes.chartWrapper}>
              <MedTypography variant='h3'>
                <Trans i18nKey='pages.Result.bar.noAtcCode'>
                  <strong>{{ drugName }}</strong>
                </Trans>
              </MedTypography>
            </div>
          )}
        <ConditionalSuccessButton />
        {appMode !== 'medWatcher' && <BannerMedWatcherSmall />}
      </div>
    </>
  )
}
export default withTranslation()(Result)
