import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { set } from 'lodash'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import NextButton from 'components/NextButton'

import seriousManImage from 'assets/serious-man.svg'
import seriousWomanImage from 'assets/serious-woman.svg'
import notSeriousManImage from 'assets/not-serious-man.svg'
import notSeriousWomanImage from 'assets/not-serious-woman.svg'
import unknownManImage from 'assets/unknown-man.svg'
import unknownWomanImage from 'assets/unknown-woman.svg'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  },
  graphics: {
    display: 'block',
    maxHeight: '30vh',
    margin: theme.spacing(0, 'auto', 2)
  }
}))

const Seriousness = ({ values, setFieldValue, goNextPage, t }) => {
  const classes = useStyles()

  let hasSerious = false
  let hasUnknown = false
  for (const effect of values.effects) {
    if (effect.isSerious === true) {
      hasSerious = true
      break
    } else if (effect.isSerious === '') {
      hasUnknown = true
    }
  }

  useEffect(() => {
    trackEvent('PageView - Seriousness')
    // Using set instead of setFieldValue since it doesn't require rerendering.
    set(values, `services.feedbackOnSeriousnessRequested`, hasUnknown)
  }, [])

  const setAndProceed = event => {
    event.preventDefault()
    trackEvent('Submit - Seriousness')
    goNextPage()
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        {hasSerious && (
          <>
            <MedTypography variant='h2'>
              <Trans i18nKey='pages.Seriousness.serious.title'>
                <strong />
              </Trans>
            </MedTypography>
            <MedTypography variant='body1'>
              <Trans i18nKey='pages.Seriousness.serious.paragraph1'>
                <strong />
                <strong />
              </Trans>
            </MedTypography>
            {values.contacts.patient.gender === 'male' && (
              <img className={classes.graphics} src={seriousManImage} alt='' />
            )}
            {values.contacts.patient.gender !== 'male' && (
              <img
                className={classes.graphics}
                src={seriousWomanImage}
                alt=''
              />
            )}
            <MedTypography variant='body1'>
              <Trans i18nKey='pages.Seriousness.serious.paragraph2'>
                <strong />
              </Trans>
            </MedTypography>
          </>
        )}
        {!hasSerious && hasUnknown && (
          <>
            <MedTypography variant='h2'>
              <Trans i18nKey='pages.Seriousness.unknown.title'>
                <strong />
              </Trans>
            </MedTypography>
            {values.contacts.patient.gender === 'male' && (
              <img className={classes.graphics} src={unknownManImage} alt='' />
            )}
            {values.contacts.patient.gender !== 'male' && (
              <img
                className={classes.graphics}
                src={unknownWomanImage}
                alt=''
              />
            )}
            <MedTypography variant='body1'>
              <Trans i18nKey='pages.Seriousness.unknown.paragraph' />
            </MedTypography>
          </>
        )}
        {!hasSerious && !hasUnknown && (
          <>
            <MedTypography variant='h2'>
              <Trans i18nKey='pages.Seriousness.notSerious.title'>
                <strong />
              </Trans>
            </MedTypography>
            <MedTypography variant='body1'>
              <Trans i18nKey='pages.Seriousness.notSerious.paragraph1' />
            </MedTypography>
            {values.contacts.patient.gender === 'male' && (
              <img
                className={classes.graphics}
                src={notSeriousManImage}
                alt=''
              />
            )}
            {values.contacts.patient.gender !== 'male' && (
              <img
                className={classes.graphics}
                src={notSeriousWomanImage}
                alt=''
              />
            )}
            <MedTypography variant='body1'>
              <Trans i18nKey='pages.Seriousness.notSerious.paragraph2'>
                <strong />
              </Trans>
            </MedTypography>
          </>
        )}
      </div>
      <NextButton label={t('pages.Seriousness.next')} onClick={setAndProceed} />
    </>
  )
}

export default withTranslation()(Seriousness)
