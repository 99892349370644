import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'

import { getLocalizedEnumeration } from 'utils/helper'
import NextButton from 'components/NextButton'
import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  }
}))
const Gender = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  goNextPage,
  t
}) => {
  const classes = useStyles()
  useEffect(() => trackEvent('PageView - Gender'), [])

  const pregnancyOptions = []
  pregnancyOptions.push({
    label: t('pages.Gender.pregnancy.options.not'),
    value: '0'
  })
  for (let week = 1; week <= 41; week++) {
    pregnancyOptions.push({
      label:
        getLocalizedEnumeration(week) + ' ' + t('pages.Gender.pregnancy.week'),
      value: week
    })
  }

  const corrections = () => {
    // perform corrections if any before moving to next page
    if (values.contacts.patient.gender === 'male') {
      setFieldValue('contacts.patient.pregnancy', {
        pregnant: '',
        week: ''
      })
    } else {
      if (values.contacts.patient.pregnancy.week !== '') {
        setFieldValue(
          'contacts.patient.pregnancy.pregnant',
          values.contacts.patient.pregnancy.week !== '0'
        )
      }
    }
  }

  const setAndProceed = event => {
    event.preventDefault()
    trackEvent('Submit - Gender')
    corrections()
    goNextPage()
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.Gender.title'>
            <strong />
          </Trans>
        </MedTypography>
        <MedDropDown
          name='contacts.patient.gender'
          label={t('pages.Gender.gender.label')}
          placeholder={t('pages.Gender.gender.placeholder')}
          dataList={[
            {
              label: t('pages.Gender.gender.options.female'),
              value: 'female'
            },
            {
              label: t('pages.Gender.gender.options.male'),
              value: 'male'
            },
            {
              label: t('pages.Gender.gender.options.other'),
              value: 'diverse'
            }
          ]}
          required
          textKey='label'
          valueKey='value'
          autoNativeOnMobile
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          value={values.contacts.patient.gender}
        />
        {(values.contacts.patient.gender === 'female' ||
          values.contacts.patient.gender === 'diverse') && (
          <MedDropDown
            name='contacts.patient.pregnancy.week'
            label={
              t('pages.Gender.pregnancy.label') +
              ' (' +
              t('common.optional') +
              ')'
            }
            placeholder={t('pages.Gender.pregnancy.placeholder')}
            dataList={pregnancyOptions}
            textKey='label'
            valueKey='value'
            autoNativeOnMobile
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contacts.patient.pregnancy.week}
          />
        )}
      </div>
      <NextButton
        disabled={!values.contacts.patient.gender}
        onClick={setAndProceed}
      />
    </>
  )
}
export default withTranslation()(Gender)
