import React, { useState, useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { has, get } from 'lodash'
import { hyphenatedText } from 'utils/helper'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import NextButton from 'components/NextButton'
import InfoTooltip from 'components/InfoTooltip'
import TimeFrame from 'containers/TimeFrame'

import { TIME_FRAME_OPTION_EXACT_DATE } from '../../constants'
import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  }
}))

const SideEffectTimeFrame = ({
  ab,
  values,
  errors,
  touched,
  itemIndex: index,
  setFieldValue,
  handleChange: formikHandleChange,
  handleBlur: formikHandleBlur,
  goNextPage,
  setSideEffects,
  t
}) => {
  const classes = useStyles()

  const [displayError, setDisplayError] = useState(false)

  const isError =
    has(errors, `effects[${index}].startDate`) ||
    (!get(values, `effects[${index}].ongoing`) &&
      has(errors, `effects[${index}].endDate`))

  const isTouched =
    has(touched, `effects[${index}].startDate`) ||
    has(touched, `effects[${index}].endDate`)

  const effectName = hyphenatedText(values.effects[index].name)

  const isDisabled = !(
    get(values, `effects[${index}].startDate.mm`) &&
    get(values, `effects[${index}].startDate.yyyy`) &&
    (get(values, `effects[${index}].ongoing`) ||
      (get(values, `effects[${index}].endDate.mm`) &&
        get(values, `effects[${index}].endDate.yyyy`)))
  )

  useEffect(
    () => trackEvent('PageView - SideEffect Time Frame ' + (index + 1)),
    []
  )

  const checkAndProceed = () => {
    if (isError) {
      setDisplayError(true)
      trackEvent('ErrorWrongDate - SideEffect Time Frame ' + (index + 1))
    } else {
      trackEvent('Submit - SideEffect Time Frame ' + (index + 1))

      if (
        values.effects[index].startDateRough === TIME_FRAME_OPTION_EXACT_DATE
      ) {
        trackEvent('Submit - SideEffect Time Frame Exact ' + (index + 1))
      } else {
        trackEvent('Submit - SideEffect Time Frame Rough ' + (index + 1))
      }
      setSideEffects(get(values, 'effects'))
      goNextPage()
    }
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.SideEffectTimeFrame.heading'>
            <strong>{{ effectName }}</strong>
          </Trans>
          {/* ab === 'b' && (
            <InfoTooltip
              paragraphs={[t('pages.SideEffectTimeFrame.tooltip')]}
              page='SideEffectTimeFrame'
            />
          ) */}
        </MedTypography>
        <TimeFrame
          labels={{
            startDate: 'pages.SideEffectTimeFrame.startDate',
            ongoingLabel: 'common.timeFrame.stillHaveIt',
            endDate: 'pages.SideEffectTimeFrame.endDate'
          }}
          values={values}
          valueGroup='effects'
          index={index}
          errors={errors}
          isError={isError}
          displayError={displayError}
          isTouched={isTouched}
          setFieldValue={setFieldValue}
          onChange={formikHandleChange}
          onBlur={formikHandleBlur}
        />
      </div>
      <NextButton disabled={isDisabled} onClick={checkAndProceed} />
    </>
  )
}

export default withTranslation()(SideEffectTimeFrame)
