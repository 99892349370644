import I18n from 'i18n'
import {
  isCovidVaccine,
  convertToPhonenumber,
  filterPossibleSuspiciousDrugs,
  getCovidVaccineName
} from 'utils/helper'
import config from '../../config'

/**
 * Converts date object to date string.
 *
 * @param {object} dateObj date object to convert, expect object keys to be 'yyyy', 'mm', 'dd'
 * @return {string} date string in form of "YYYY-MM-DD"
 */

const HABIT_DESCRIPTIONS = {
  smoking: 'Starker Raucher',
  drinking: 'Hoher Alkoholkonsum',
  sport: 'Wenig Bewegung'
}

const _convertDateObjToDateStr = dateObj =>
  [dateObj.yyyy, dateObj.mm, dateObj.dd]
    .filter(num => num > 0) // omit value if (undefined OR non-numeric OR not-positive)
    .map(x => (x < 10 ? '0' + x : x)) // prefix '0' for single digit values
    .join('-')

const _correctEffectsData = data => {
  return data.map(effect => {
    if (effect.startDateRough !== 'exactDate') {
      effect.startDate.dd = ''

      // If the start date is exact so has to be the endDate
      if (effect.endDateRough !== 'exactDate') {
        effect.endDate.dd = ''
      }
    }
    return {
      name: effect.name,
      startDate: _convertDateObjToDateStr(effect.startDate),
      ...(effect.endDate &&
        effect.endDate.yyyy &&
        effect.endDate.mm && {
          endDate: _convertDateObjToDateStr(effect.endDate)
        }),
      ...((effect.llt_code || effect.pt_code) && {
        codes: {
          ...(effect.llt_code && { meddra_llt: effect.llt_code }),
          ...(effect.pt_code && { meddra_pt: effect.pt_code }),
          ...(effect.meddra_version && {
            meddra_version: effect.meddra_version
          })
        }
      }),
      ...(typeof effect.isSerious === 'boolean' && {
        isSerious: effect.isSerious
      }),
      ...(effect.ongoing === true && {
        status: 'ongoing'
      })
    }
  })
}

const _correctDrugsData = (data, suspectedDrugs, primaryDrug) => {
  return data.map(drug => {
    if (!isCovidVaccine(drug) && drug.startDateRough !== 'exactDate') {
      drug.startDate.dd = ''

      // If the start date is exact so has to be the endDate
      if (!isCovidVaccine(drug) && drug.endDateRough !== 'exactDate') {
        drug.endDate.dd = ''
      }
    }
    const obj = {
      name: drug.medicine,
      startDate: _convertDateObjToDateStr(drug.startDate),
      ...(drug.endDate &&
        drug.endDate.yyyy &&
        drug.endDate.mm && {
          endDate: _convertDateObjToDateStr(drug.endDate)
        }),
      ...((drug.atc_international || drug.pzn) && {
        codes: {
          ...(drug.atc_international && { atc: drug.atc_international }),
          ...(drug.pzn && { pzn: drug.pzn })
        }
      }),
      ...(drug.firmenname && { manufacturer: drug.firmenname }),
      ...(suspectedDrugs.includes(drug.medicine) && {
        suspicious: true
      }),
      ...(drug.medicine === primaryDrug && {
        primary: true
      })
    }
    if (drug.prefilled) {
      delete drug.prefilled
    }
    if (drug.vaccinedose !== undefined) {
      switch (drug.vaccinedose) {
        case 'first':
          obj.name += ' (' + I18n.t('common.vaccines.firstVaccine') + ')'
          break
        case 'second':
          obj.name += ' (' + I18n.t('common.vaccines.secondVaccine') + ')'
          break
        case 'third':
          obj.name += ' (' + I18n.t('common.vaccines.thirdVaccine') + ')'
          break
      }
      delete drug.vaccinedose
    }
    if (drug.dose !== undefined) {
      if (!drug.dose.unit) {
        drug.dose.unit = 'unknown'
      }
      if (
        drug.dose.amount !== undefined &&
        (drug.dose.amount === '' || isNaN(drug.dose.amount))
      ) {
        delete drug.dose.amount
      }
      if (!drug.dose.interval) {
        drug.dose.interval = 'unknown'
      }
      // At this point neither unit nor interval can be undefined
      if (drug.dose.amount !== undefined) {
        obj.dose = drug.dose
      }
    }
    return obj
  })
}

const _convertCovidVaccinesToDrugs = (vaccines, suspectedDrugs, primaryDrug) =>
  vaccines
    .filter(vaccine => vaccine.type !== 'none')
    .map(vaccine => {
      const name = getCovidVaccineName(vaccine)
      const date = _convertDateObjToDateStr(vaccine.date)
      return {
        name,
        startDate: date,
        endDate: date,
        ...(vaccine.type !== 'other' && {
          codes: {
            atc: config.COVID_VACCINES[vaccine.type]
          }
        }),
        ...(suspectedDrugs.includes(name) && {
          suspicious: true
        }),
        ...(name === primaryDrug && {
          primary: true
        })
      }
    })

const _correctContactsData = (data, temp) => {
  if (!data.patient.height) {
    delete data.patient.height
  } else {
    data.patient.height = parseInt(data.patient.height)
  }
  if (!data.patient.weight) {
    delete data.patient.weight
  } else {
    data.patient.weight = parseInt(data.patient.weight)
  }

  if (data.patient.dateOfBirthYear !== undefined) {
    data.patient.dateOfBirthYear = parseInt(data.patient.dateOfBirthYear)
  }

  if (data.patient.gender === 'male') {
    // when male selected, delete pregnancy object
    delete data.patient.pregnancy
  } else {
    // when pregnancy not selected, delete pregnancy object
    if (!data.patient.pregnancy.pregnant) {
      delete data.patient.pregnancy
    }

    // when 'not pregnant' selected, delete pregnancy.week
    if (data.patient.pregnancy && data.patient.pregnancy.week) {
      if (data.patient.pregnancy.week === '0') {
        delete data.patient.pregnancy.week
      } else {
        data.patient.pregnancy.week = parseInt(data.patient.pregnancy.week)
      }
    }
  }
  // when mobile number is selected, return mobile number as email
  if (temp.email.usePhone) {
    const formattedPhonenumber = convertToPhonenumber(
      data.reporter.identity.phone
    )
    data.reporter.identity.email =
      config.PHONE_MAIL_PREFIX +
      '+' +
      formattedPhonenumber +
      config.PHONE_MAIL_POSTFIX
  }
  delete data.reporter.identity.phone
  return data
}

const _correctDiagnosesData = (data, comments) => {
  data.forEach(item => {
    if (comments[item.class] !== undefined) {
      item.description = comments[item.class]
    }
  })
  return data
}

const _correctHabitsData = (data, comments) => {
  const obj = {}
  data.forEach(item => {
    if (item === 'smoking' || item === 'drinking' || item === 'sport') {
      obj[item] = {}
      if (comments[item] !== undefined) {
        obj[item].description = comments[item]
      } else {
        obj[item].description = HABIT_DESCRIPTIONS[item]
      }
    } else {
      if (!obj.others) obj.others = []
      const childObj = { name: item }
      if (comments[item] !== undefined) {
        childObj.description = comments[item]
      }
      obj.others.push(childObj)
    }
  })
  return obj
}

const getReportPayload = formValues => {
  const possibleSuspiciousDrugs = filterPossibleSuspiciousDrugs(
    formValues.effects,
    formValues.temp.vaccines.covid,
    formValues.drugs
  )
  if (possibleSuspiciousDrugs.length === 1) {
    if (possibleSuspiciousDrugs[0].medicine) {
      formValues.suspectedDrugs = possibleSuspiciousDrugs[0].medicine
      formValues.primaryDrug = possibleSuspiciousDrugs[0].medicine
    } else {
      formValues.suspectedDrugs = getCovidVaccineName(
        possibleSuspiciousDrugs[0]
      )
      formValues.primaryDrug = possibleSuspiciousDrugs[0]
    }
  }
  return {
    effects: _correctEffectsData(formValues.effects),
    drugs: [
      ..._correctDrugsData(
        formValues.drugs,
        formValues.suspectedDrugs,
        formValues.primaryDrug
      ),
      ..._convertCovidVaccinesToDrugs(
        formValues.temp.vaccines.covid,
        formValues.suspectedDrugs,
        formValues.primaryDrug
      )
    ],
    contacts: _correctContactsData(formValues.contacts, formValues.temp),
    ...(formValues.diagnoses.length && {
      diagnoses: _correctDiagnosesData(
        formValues.diagnosesObject,
        formValues.diagnoseComments
      )
    }),
    ...(formValues.habits.length && {
      habits: _correctHabitsData(formValues.habits, formValues.habitComments)
    }),
    ...(formValues.description && { description: formValues.description }),
    legal: formValues.legal,
    ...(formValues.services && { services: formValues.services })
  }
}

export { getReportPayload }
