import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { get, has } from 'lodash'

import MedCheckBox from '@bit/medikura.med-lib-uno.med-check-box'
import MedDropDown from '@bit/medikura.med-lib-uno.med-drop-down'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import DateInput from 'components/DateInput'

import { TIME_FRAME_OPTION_EXACT_DATE } from '../../constants'

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginBottom: theme.spacing(2),
    '& .MuiButtonBase-root': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  checkboxLabel: {}
}))
const TIME_FRAME_OPTION_ONGOING = 'ongoing'

const TimeFrame = ({
  labels,
  values,
  valueGroup,
  index,
  errors,
  isError,
  displayError,
  isTouched,
  setFieldValue,
  onChange: formikHandleChange,
  onBlur: formikHandleBlur,
  t
}) => {
  const classes = useStyles()

  const [isMounting, setMounting] = useState(true)

  useEffect(() => {
    if (!isMounting) {
      if (values[valueGroup][index].ongoing) {
        setFieldValue(`${valueGroup}[${index}].endDate`, {
          dd: '',
          mm: '',
          yyyy: ''
        })
      }
    }
  }, [values[valueGroup][index].ongoing])

  useEffect(() => {
    if (!isMounting) {
      if (
        values[valueGroup][index].startDateRough ===
        TIME_FRAME_OPTION_EXACT_DATE
      ) {
        setFieldValue(`${valueGroup}[${index}].startDate`, {
          dd: '',
          mm: '',
          yyyy: ''
        })
      } else {
        setFieldValue(
          `${valueGroup}[${index}].startDate`,
          convertTimeFrameOptionToDate(values[valueGroup][index].startDateRough)
        )
      }
      setFieldValue(`${valueGroup}[${index}].endDateRough`, '')
      setFieldValue(`${valueGroup}[${index}].ongoing`, false)
      setFieldValue(`${valueGroup}[${index}].endDate`, {
        dd: '',
        mm: '',
        yyyy: ''
      })
    }
  }, [values[valueGroup][index].startDateRough])

  useEffect(() => {
    if (!isMounting) {
      if (
        values[valueGroup][index].endDateRough === TIME_FRAME_OPTION_ONGOING
      ) {
        setFieldValue(`${valueGroup}[${index}].ongoing`, true)
        setFieldValue(`${valueGroup}[${index}].endDate`, {
          dd: '',
          mm: '',
          yyyy: ''
        })
      } else {
        setFieldValue(`${valueGroup}[${index}].ongoing`, false)
        if (
          values[valueGroup][index].endDateRough ===
          TIME_FRAME_OPTION_EXACT_DATE
        ) {
          setFieldValue(`${valueGroup}[${index}].endDate`, {
            dd: '',
            mm: '',
            yyyy: ''
          })
        } else {
          setFieldValue(
            `${valueGroup}[${index}].endDate`,
            convertTimeFrameOptionToDate(values[valueGroup][index].endDateRough)
          )
        }
      }
    }
  }, [values[valueGroup][index].endDateRough])

  useEffect(() => {
    setMounting(false)
  }, [])

  const getTimeFrameOptions = (
    ongoingOptionLabel = null,
    previousOption = null
  ) => {
    const options = []
    if (ongoingOptionLabel !== null) {
      options.push({
        label: ongoingOptionLabel,
        value: TIME_FRAME_OPTION_ONGOING
      })
    }

    const optionKeys = [
      TIME_FRAME_OPTION_EXACT_DATE,
      'weekOne',
      'weekTwo',
      'weekThree',
      'monthsOne',
      'monthsTwo',
      'monthsFour',
      'monthsSix',
      'monthsEight',
      'monthsTen',
      'yearsOne',
      'yearsTwo',
      'yearsFour',
      'yearsSix',
      'yearsEight',
      'yearsTen',
      'yearsFifteen',
      'yearsTwenty',
      'yearsThirty'
    ]
    for (const optionKey of optionKeys) {
      options.push({
        label: t('common.timeFrame.' + optionKey),
        value: optionKey
      })
      if (optionKey === previousOption) {
        break
      }
    }
    return options
  }

  const convertTimeFrameOptionToDate = option => {
    if (!option) {
      return { dd: '', mm: '', yyyy: '' }
    }

    const dateObj = new Date()
    switch (option) {
      case 'weekOne':
        dateObj.setDate(dateObj.getDate() - 7)
        break
      case 'weekTwo':
        dateObj.setDate(dateObj.getDate() - 14)
        break
      case 'weekThree':
        dateObj.setDate(dateObj.getDate() - 21)
        break
      case 'monthsOne':
        dateObj.setMonth(dateObj.getMonth() - 1)
        break
      case 'monthsTwo':
        dateObj.setMonth(dateObj.getMonth() - 2)
        break
      case 'monthsFour':
        dateObj.setMonth(dateObj.getMonth() - 4)
        break
      case 'monthsSix':
        dateObj.setMonth(dateObj.getMonth() - 6)
        break
      case 'monthsEight':
        dateObj.setMonth(dateObj.getMonth() - 8)
        break
      case 'monthsTen':
        dateObj.setMonth(dateObj.getMonth() - 10)
        break
      case 'yearsOne':
        dateObj.setFullYear(dateObj.getFullYear() - 1)
        break
      case 'yearsTwo':
        dateObj.setFullYear(dateObj.getFullYear() - 2)
        break
      case 'yearsFour':
        dateObj.setFullYear(dateObj.getFullYear() - 4)
        break
      case 'yearsSix':
        dateObj.setFullYear(dateObj.getFullYear() - 6)
        break
      case 'yearsEight':
        dateObj.setFullYear(dateObj.getFullYear() - 8)
        break
      case 'yearsTen':
        dateObj.setFullYear(dateObj.getFullYear() - 10)
        break
      case 'yearsFifteen':
        dateObj.setFullYear(dateObj.getFullYear() - 15)
        break
      case 'yearsTwenty':
        dateObj.setFullYear(dateObj.getFullYear() - 20)
        break
      case 'yearsThirty':
        dateObj.setFullYear(dateObj.getFullYear() - 30)
        break
      default:
      // Do nothing
    }

    return {
      dd: dateObj.getDate(),
      mm: dateObj.getMonth() + 1,
      yyyy: dateObj.getFullYear()
    }
  }

  return (
    <>
      <MedDropDown
        name={`${valueGroup}[${index}].startDateRough`}
        label={t(labels.startDate)}
        placeholder={t('common.timeFrame.placeholder')}
        autoNativeOnMobile
        required
        dataList={getTimeFrameOptions() || ''}
        textKey='label'
        valueKey='value'
        value={values[valueGroup][index].startDateRough}
        onChange={formikHandleChange}
        onBlur={formikHandleBlur}
        error={
          displayError &&
          isTouched &&
          isError &&
          values[valueGroup][index].startDateRough !==
            TIME_FRAME_OPTION_EXACT_DATE &&
          has(errors, `${valueGroup}[${index}].startDate`)
        }
        helperText={
          displayError &&
          isTouched &&
          isError &&
          values[valueGroup][index].startDateRough !==
            TIME_FRAME_OPTION_EXACT_DATE &&
          has(errors, `${valueGroup}[${index}].startDate`)
            ? t(
                'common.dateInput.errorText.' +
                  get(errors, `${valueGroup}[${index}].startDate`),
                t('common.dateInput.errorText.default')
              )
            : ''
        }
      />
      {values[valueGroup][index].startDateRough ===
        TIME_FRAME_OPTION_EXACT_DATE && (
        <>
          <DateInput
            name={`${valueGroup}[${index}].startDate`}
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
            autoFocus
            value={
              values[valueGroup][index].startDate || {
                dd: '',
                mm: '',
                yyyy: ''
              }
            }
            error={
              displayError &&
              isTouched &&
              isError &&
              get(errors, `${valueGroup}[${index}].startDate`)
            }
          />
          {get(values, `${valueGroup}[${index}].startDate.mm`) &&
            get(values, `${valueGroup}[${index}].startDate.yyyy`) && (
              <>
                <MedCheckBox
                  className={classes.checkbox}
                  name={`${valueGroup}[${index}].ongoing`}
                  value={values[valueGroup][index].ongoing || false}
                  checked={values[valueGroup][index].ongoing || false}
                  onChange={formikHandleChange}
                  label={t(labels.ongoingLabel)}
                />
                {!values[valueGroup][index].ongoing && (
                  <>
                    <MedTypography variant='h3'>
                      {t(labels.endDate)}
                    </MedTypography>
                    <DateInput
                      name={`${valueGroup}[${index}].endDate`}
                      onChange={formikHandleChange}
                      onBlur={formikHandleBlur}
                      autoFocus
                      value={
                        values[valueGroup][index].endDate || {
                          dd: '',
                          mm: '',
                          yyyy: ''
                        }
                      }
                      error={
                        displayError &&
                        isTouched &&
                        isError &&
                        get(errors, `${valueGroup}[${index}].endDate`)
                      }
                    />
                  </>
                )}
              </>
            )}
        </>
      )}
      {values[valueGroup][index].startDateRough !== undefined &&
        values[valueGroup][index].startDateRough !== '' &&
        values[valueGroup][index].startDateRough !==
          TIME_FRAME_OPTION_EXACT_DATE && (
          <>
            <MedDropDown
              name={`${valueGroup}[${index}].endDateRough`}
              label={t(labels.endDate)}
              placeholder={t('common.timeFrame.placeholder')}
              autoNativeOnMobile
              required
              dataList={getTimeFrameOptions(
                t(labels.ongoingLabel),
                values[valueGroup][index].startDateRough
              )}
              textKey='label'
              valueKey='value'
              value={values[valueGroup][index].endDateRough || ''}
              onChange={formikHandleChange}
              onBlur={formikHandleBlur}
              error={
                displayError &&
                isTouched &&
                isError &&
                values[valueGroup][index].endDateRough !==
                  TIME_FRAME_OPTION_EXACT_DATE &&
                has(errors, `${valueGroup}[${index}].endDate`)
              }
              helperText={
                displayError &&
                isTouched &&
                isError &&
                values[valueGroup][index].endDateRough !==
                  TIME_FRAME_OPTION_EXACT_DATE &&
                has(errors, `${valueGroup}[${index}].endDate`)
                  ? t(
                      'common.dateInput.errorText.' +
                        get(errors, `${valueGroup}[${index}].endDate`),
                      t('common.dateInput.errorText.default')
                    )
                  : ''
              }
            />
            {values[valueGroup][index].endDateRough !== undefined &&
              values[valueGroup][index].endDateRough !== '' &&
              values[valueGroup][index].endDateRough ===
                TIME_FRAME_OPTION_EXACT_DATE && (
                <DateInput
                  name={`${valueGroup}[${index}].endDate`}
                  onChange={formikHandleChange}
                  onBlur={formikHandleBlur}
                  autoFocus
                  value={
                    values[valueGroup][index].endDate || {
                      dd: '',
                      mm: '',
                      yyyy: ''
                    }
                  }
                  error={
                    displayError &&
                    isTouched &&
                    isError &&
                    get(errors, `${valueGroup}[${index}].endDate`)
                  }
                />
              )}
          </>
        )}
    </>
  )
}

export default withTranslation()(TimeFrame)
