import * as Yup from 'yup'

import SideEffectsSchema from 'pages/SideEffect/validation'
import DrugsSchema from 'pages/Drugs/validation'
import BodySchema from 'pages/Body/validation'
import EmailSchema from 'pages/Email/validation'
import CovidVaccinesSchema from 'pages/CovidVaccines/validation'

const reportValidationSchema = Yup.object().shape({
  ...SideEffectsSchema,
  ...DrugsSchema,
  contacts: Yup.object().shape({
    patient: Yup.object().shape({
      ...BodySchema
    }),
    reporter: Yup.object().shape({
      ...EmailSchema
    })
  }),
  temp: Yup.object().shape({
    vaccines: Yup.object().shape({
      ...CovidVaccinesSchema
    })
  })
})

export default reportValidationSchema
