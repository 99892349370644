import React from 'react'
import clsx from 'clsx'

import Footer from 'components/Footer'
import { makeStyles } from '@material-ui/core/styles'

import { getStringParamFromRootData } from 'utils/params'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100vw',
    maxWidth: '720px',
    margin: '0 auto',
    overflow: 'hidden'
  },
  defaultWrapper: {
    minHeight: '100vh',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 20px 0px'
  },
  flexContainer: {
    padding: '0 20px 20px'
  },
  container: {
    maxWidth: '520px',
    margin: '0 auto'
  }
}))
const appMode = getStringParamFromRootData('mode', 'default')

const WizardWrapper = ({ children }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        classes.wrapper,
        appMode === 'default' ? classes.defaultWrapper : ''
      )}
    >
      <div className={classes.flexContainer}>
        <div className={classes.container}>{children}</div>
      </div>

      {appMode !== 'medWatcher' && <Footer />}
    </div>
  )
}
export default WizardWrapper
