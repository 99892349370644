import React from 'react'
import IdleTimer from 'react-idle-timer'

import { trackEvent } from 'service/tracker'

class UnloadPrevention extends React.Component {
  constructor (props) {
    super(props)

    this.idleTimer = null
    this.state = {
      start: new Date().getTime()
    }

    window.addEventListener('beforeunload', e => this.handleOnLeavePage(e))
  }

  handleOnLeavePage (event) {
    const { formik, currentPageIndex, pages } = this.props
    const { start } = this.state
    const now = new Date().getTime()

    trackEvent(
      'Close Widget',
      'Close Widget  ' + (pages[currentPageIndex].name || ''),
      true,
      Math.round((now - start) / 1000)
    )
    trackEvent(
      'Close Widget Idle',
      '',
      true,
      Math.round((now - (this.idleTimer.state.lastActive || 0)) / 1000)
    )

    if (formik.dirty === false) {
      // Don't block if the form hasn't been changed
      return
    }

    if (
      pages[currentPageIndex].category === 'complete' ||
      pages[currentPageIndex].category === 'result'
    ) {
      // Don't block closing on completion or result page
      return
    }

    event.preventDefault()
    event.returnValue = ''
  }

  render () {
    return (
      <IdleTimer
        ref={ref => {
          this.idleTimer = ref
        }}
        events={[
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mousewheel',
          'mousedown',
          'touchstart',
          'MSPointerDown'
        ]}
      />
    )
  }
}

export default UnloadPrevention
