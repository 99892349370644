import React, { useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedCheckBoxGroup from '@bit/medikura.med-lib-uno.med-check-box-group'

import NextButton from 'components/NextButton'
import {
  filterPossibleSuspiciousDrugs,
  getCovidVaccineName
} from 'utils/helper'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  }
}))

const SuspectedDrugs = props => {
  const classes = useStyles()

  useEffect(() => trackEvent('PageView - Drugs Suspect'), [])

  const handleNext = event => {
    event.preventDefault()

    const primary = []
    const nonPrimary = []
    props.values.drugs.forEach((item, index) => {
      if (props.values.suspectedDrugs.includes(item.medicine)) {
        primary.push(index)
      } else {
        nonPrimary.push(index)
      }
    })
    if (
      !props.values.primaryDrug ||
      !props.values.suspectedDrugs.includes(props.values.primaryDrug)
    ) {
      if (
        props.values.suspectedDrugs.length === 0 ||
        (props.values.suspectedDrugs.length === 1 &&
          props.values.suspectedDrugs.includes('-'))
      ) {
        props.values.primaryDrug = ''
      } else if (props.values.suspectedDrugs[0] !== '-') {
        props.values.primaryDrug = props.values.suspectedDrugs[0]
      } else {
        props.values.primaryDrug = props.values.suspectedDrugs[1]
      }
    }
    trackEvent('Submit - Drugs Suspect')

    // Concat always results in a different object
    props.setDrugs(
      primary
        .concat(nonPrimary)
        .map(index => ({ index, drug: props.values.drugs[index] })),
      () => {
        props.validate()
        props.goNextPage()
      }
    )
  }

  const dataList = [
    {
      label: props.t('pages.SuspectedDrugs.unknown'),
      value: '-'
    }
  ]
  filterPossibleSuspiciousDrugs(
    props.values.effects,
    props.values.temp.vaccines.covid,
    props.values.drugs
  ).forEach(item => {
    dataList.push(
      item.medicine
        ? {
            label: item.medicine,
            value: item.medicine
          }
        : {
            label: getCovidVaccineName(item),
            value: getCovidVaccineName(item)
          }
    )
  })
  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.SuspectedDrugs.heading'>
            <strong />
          </Trans>{' '}
          ({props.t('common.optional')})
          {/* props.ab === 'b' && (
            <InfoTooltip
              paragraphs={[
                props.t('pages.SuspectedDrugs.tooltip.paragraph1'),
                props.t('pages.SuspectedDrugs.tooltip.paragraph2')
              ]}
              page='SuspectedDrugs'
            />
          ) */}
        </MedTypography>
        <MedCheckBoxGroup
          name='suspectedDrugs'
          value={props.values.suspectedDrugs}
          dataList={dataList}
          textKey='label'
          valueKey='value'
          onChange={(field, value) => props.setFieldValue(field, value)}
          onBlur={props.handleBlur}
          medStyle='med-style-rounded'
        />
      </div>
      <NextButton onClick={handleNext} />
    </>
  )
}
export default withTranslation()(SuspectedDrugs)
