import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation, Trans } from 'react-i18next'

import Link from '@material-ui/core/Link'

import MedButton from '@bit/medikura.med-lib-uno.med-button'

import { ReactComponent as MedWatcherLogo } from 'assets/medwatcher.svg'
import { ReactComponent as MedWatcherWomanImageComplete } from 'assets/medwatcher-woman.svg'

const useStyles = makeStyles(theme => ({
  medWatcherLink: {
    textDecoration: 'none !important'
  },
  medWatcherWrapperComplete: {
    borderRadius: '12px',
    border: '1px solid #0F4C5C',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
  },
  medWatcherHeader: {
    borderRadius: '11px 11px 0 0',
    background: '#0F4C5C',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '15px',
    padding: '8px 16px 11px'
  },
  medWatcherLogo: {
    display: 'block',
    height: '32px',
    margin: '12px auto 14px'
  },
  medWatcherContentComplete: {
    padding: '0 24px 21px'
  },
  medWatcherLine: {
    height: '1px',
    background: '#35AEB1',
    clear: 'both'
  },
  medWatcherImage: {
    float: 'left',
    width: '40%',
    marginRight: '8px',
    ['@media (max-width:470px)']: {
      display: 'none'
    }
  },
  medWatcherHeadline: {
    margin: '12px 0 8px',
    fontSize: '16px',
    color: '#0f4c5c',
    ['@media (max-width:470px)']: {
      textAlign: 'center'
    }
  },
  medWatcherInfotext: {
    fontSize: '14px',
    color: '#35aeb1',
    ['@media (max-width:470px)']: {
      textAlign: 'center',
      '& + &': {
        marginBottom: '8px'
      }
    }
  },
  medWatcherList: {
    maxWidth: '240px',
    fontSize: '14px',
    padding: '0 0 0 1em',
    margin: '0 auto',
    listStyle: 'none',
    '& li': {
      marginTop: '6px',
      '&:before': {
        content: '"•"',
        color: '#35AEB1',
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em'
      }
    },
    '& li:before': {
      content: '•',
      color: 'red',
      fontWeight: 'bold',
      display: 'inline-block',
      width: '1em',
      marginLeft: '-1em'
    }
  },
  medWatcherSubmit: {
    marginTop: '16px',
    background: '#0F4C5C',
    '&:hover': {
      background: '#2e6475'
    }
  }
}))

const BannerMedWatcherLarge = props => {
  const classes = useStyles()
  return (
    <Link
      href='https://www.medwatcher.io'
      target='_blank'
      className={classes.medWatcherLink}
    >
      <div className={classes.medWatcherWrapperComplete}>
        <div className={classes.medWatcherHeader}>
          {props.t('bannerMedWatcher.large.title')}
        </div>
        <div className={classes.medWatcherContentComplete}>
          <MedWatcherLogo className={classes.medWatcherLogo} />
          <div className={classes.medWatcherLine} />
          <MedWatcherWomanImageComplete className={classes.medWatcherImage} />
          <div className={classes.medWatcherHeadline}>
            {props.t('bannerMedWatcher.large.headline')}
          </div>
          <div className={classes.medWatcherInfotext}>
            {props.t('bannerMedWatcher.large.infoText')}
          </div>
          <div className={classes.medWatcherLine} />
          <ul className={classes.medWatcherList}>
            <li>{props.t('bannerMedWatcher.large.listTracking')}</li>
            <li>{props.t('bannerMedWatcher.large.listSafety')}</li>
            <li>{props.t('bannerMedWatcher.large.listCompare')}</li>
            <li>{props.t('bannerMedWatcher.large.listQuestion')}</li>
          </ul>
          <MedButton fullWidth className={classes.medWatcherSubmit}>
            {props.t('bannerMedWatcher.large.submit')}
          </MedButton>
        </div>
      </div>
    </Link>
  )
}

export default withTranslation()(BannerMedWatcherLarge)
