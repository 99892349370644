import React, { useEffect } from 'react'

import { withTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import NextButton from 'components/NextButton'

import { trackEvent } from 'service/tracker'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  }
}))

const DiagnoseComments = ({
  item,
  handleChange,
  handleBlur,
  values,
  goNextPage,
  t
}) => {
  const classes = useStyles()
  useEffect(() => trackEvent('PageView - DiagnoseComment ' + item), [])

  const setAndProceed = event => {
    event.preventDefault()
    trackEvent('Submit - DiagnoseComment ' + item)
    goNextPage()
  }

  const itemName = t('pages.Diagnoses.options.' + item)
  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>
          <Trans i18nKey='pages.DiagnoseComments.heading'>
            <strong>{{ itemName }}</strong>
          </Trans>{' '}
          ({t('common.optional')})
        </MedTypography>
        <MedTextField
          name={`diagnoseComments.${item}`}
          placeholder={t('pages.DiagnoseComments.textfield')}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          value={values.diagnoseComments[item] || ''}
          multiline
        />
      </div>
      <NextButton onClick={setAndProceed} />
    </>
  )
}
export default withTranslation()(DiagnoseComments)
